const queryConfig = {
  defaultOptions: {
    shared: {
      suspense: true,
    },
    queries: {
      cacheTime: 0,
      retry: 1,
      refetchOnWindowFocus: false,
    },
  }
};

export default queryConfig;
