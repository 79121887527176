import React, { useState, useEffect } from "react";
import { useMutation } from "react-query";
import { useFormik, Form, FormikProvider } from 'formik';
import { Row, Col } from "antd";

import PageTitleWrapper from "components/pageTitleWrapper";
import FormWrapperInput from "components/formWrapperInput";
import Button from "components/button";
import { getCurrentUser } from "utils/axiosConfig";

import { changePassword } from "services/user.service";

const Password = () => {
  const jsonUser = getCurrentUser();
  const currentUser = jsonUser ? JSON.parse(jsonUser) : null;

  const [initialValues, setInitialValues] = useState({
    userId: currentUser?.uId,
    currentPassword: "",
    newPassword: "",
    confirmPassword: ""
  });

  const submitPassword = useMutation(values => {
    return changePassword({values});
  });

  const onSubmit = (values) => {
    submitPassword.mutate(values, {
      onSuccess: (res) => {
        if (res) {
          console.log(res)
        }
      }
    });
  }

  const checkValidityPass = (values) => {
    const errors = {};

    ["currentPassword", "newPassword", "confirmPassword"].map((key) => {
      if (!values[key]) {
        errors[key] = "The field is required";
      } else {
        if (values[key].length < 6) {
          [key] = "Password cannot be less than 6 characters";
        }
      } 

      return false;
    });

    if (!errors.newPassword && !errors.confirmPassword) {
      if (values.newPassword !== values.confirmPassword) {
        errors.confirmPassword = "Password not match with new password";
      }
    }

    return errors;
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validate:checkValidityPass,
    onSubmit: (values) => onSubmit(values)
  });

  const { touched, errors, values, handleSubmit, setFieldValue } = formik;

  return (
    <div className="body-wrapper">
      <PageTitleWrapper className="title-wrapper">
        <h2>Change Password</h2>
      </PageTitleWrapper>

      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Row gutter={16}>
            <Col span={12}>
              <FormWrapperInput 
                label="Current Password"
                name="currentPassword"
                type="password"
                value={values.currentPassword}
                onChange={(e) => setFieldValue("currentPassword", e.target.value)}
                error={Boolean(touched.currentPassword && errors.currentPassword)}
                helpertext={touched.currentPassword && errors.currentPassword}
              />
            </Col>
            <Col span={12}>
              <FormWrapperInput 
                label="New Password"
                name="newPassword"
                type="password"
                value={values.newPassword}
                onChange={(e) => setFieldValue("newPassword", e.target.value)}
                error={Boolean(touched.newPassword && errors.newPassword)}
                helpertext={touched.newPassword && errors.newPassword}
              />
            </Col>
            <Col span={12}>
              <FormWrapperInput 
                label="Confirm Password"
                name="confirmPassword"
                type="password"
                value={values.confirmPassword}
                onChange={(e) => setFieldValue("confirmPassword", e.target.value)}
                error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                helpertext={touched.confirmPassword && errors.confirmPassword}
              />
            </Col>
            <Col span={24}>
              <Button 
                className="mt-2" 
                name="Submit" 
                htmlType="submit"
              />
            </Col>
          </Row>
        </Form>
      </FormikProvider>
    </div>
  );
}

export default Password;
