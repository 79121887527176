import React from "react";
import * as Yup from 'yup';
import PropTypes from "prop-types";
import { Row, Col } from "antd";
import { useFormik, Form, FormikProvider, FieldArray } from 'formik';
import { PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';
import styled from "styled-components";

import FormWrapperInput from "components/formWrapperInput";
import Button from "components/button";
import { countries, getStates, getCities } from "utils/common";
import { formatInputDate } from "utils/formatTime";

const AddressWrapper = styled.div`
  width: 50%;

  > div {
    display: inline-block;
    width: 95%;
  }

  .anticon {
    display: inline-block;
    margin-left: 10px;
    cursor: pointer;
  }
`;

const UserForm = ({
  initialValues,
  isEdit,
  onSubmit
}) => {
  let UserSchema = Yup.object().shape({
    userName: Yup.string().required('Username is required'),
    email: Yup.string().required('Email is required'),
    password: Yup.string().required('Password is required'),
    confirmPassword: Yup.string().required('Confirm Password is required'),
  });

  if (isEdit) {
    UserSchema = Yup.object().shape({
      userName: Yup.string().required('Username is required'),
      email: Yup.string().required('Email is required'),
    });
  }

  const formik = useFormik({
    initialValues,
    validationSchema: UserSchema,
    enableReinitialize: true,
    onSubmit: (values) => onSubmit(values)
  });

  const { touched, errors, values, handleSubmit, getFieldProps, setFieldValue } = formik;

  const optionCountries = countries.map((country) => (
    {
      label: country.name,
      value: country.shortName
    }
  ));

  const getOptionStates = () => {
    const options = getStates(getFieldProps("country").value);

    return options.map((value) => (
      {
        label: value,
        value: value
      }
    ))
  }

  const getOptionCities = () => {
    const country = getFieldProps("country").value;
    const state = getFieldProps("state").value;
    const options = getCities(country, state);

    return options.map((value) => (
      {
        label: value,
        value: value
      }
    ))
  }

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Row gutter={16}>
          <Col span={8}>
            <FormWrapperInput 
              label="Username"
              name="userName"
              type="input"
              value={values.userName}
              onChange={(e) => setFieldValue("userName", e.target.value)}
              error={Boolean(touched.userName && errors.userName)}
              helpertext={touched.userName && errors.userName}
            />
          </Col>
          <Col span={8}>
            <FormWrapperInput 
              label="National Id"
              name="nationalId"
              type="input"
              value={values.nationalId}
              onChange={(e) => setFieldValue("nationalId", e.target.value)}
            />
          </Col>
          <Col span={8}>
            <FormWrapperInput 
              label="Date Of Birth"
              name="dateOfBirth"
              type="date"
              value={values.dateOfBirth ? formatInputDate(values.dateOfBirth) : ""}
              onChange={(_, dateString) => setFieldValue("dateOfBirth", dateString)}
            />
          </Col>
          {/* break */}
          <Col span={12}>
            <FormWrapperInput 
              label="Email"
              name="email"
              type="input"
              value={values.email}
              onChange={(e) => setFieldValue("email", e.target.value)}
              error={Boolean(touched.email && errors.email)}
              helpertext={touched.email && errors.email}
            />
          </Col>
          <Col span={12}>
            <FormWrapperInput 
              label="Contact No"
              name="contactNo"
              type="input"
              value={values.contactNo}
              onChange={(e) => setFieldValue("contactNo", e.target.value)}
            />
          </Col>
          {/* Break */}
          {
            !isEdit && (
              <React.Fragment>
                <Col span={12}>
                  <FormWrapperInput 
                    label="Password"
                    name="password"
                    type="password"
                    onChange={(e) => setFieldValue("password", e.target.value)}
                    error={Boolean(touched.password && errors.password)}
                    helpertext={touched.password && errors.password}
                  />
                </Col>
                <Col span={12}>
                  <FormWrapperInput 
                    label="Confirm Password"
                    name="confirmPassword"
                    type="password"
                    onChange={(e) => setFieldValue("confirmPassword", e.target.value)}
                    error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                    helpertext={touched.confirmPassword && errors.confirmPassword}
                  />
                </Col>
              </React.Fragment>
            )
          }
          {/* Break */}
          <Col span={12}>
            <FormWrapperInput 
              label="Country"
              name="country"
              type="select"
              value={values.country}
              options={optionCountries ? optionCountries : []}
              onChange={(e) => setFieldValue("country", e)}
            />
          </Col>
          <Col span={12}>
            <FormWrapperInput 
              label="State"
              name="state"
              type="select"
              value={values.state}
              options={getOptionStates()}
              onChange={(e) => setFieldValue("state", e)}
            />
          </Col>
          {/* Break */}
          <Col span={12}>
            <FormWrapperInput 
              label="City"
              name="city"
              type="select"
              value={values.city}
              options={getOptionCities()}
              onChange={(e) => setFieldValue("city", e)}
            />
          </Col>
          <Col span={12}>
            <FormWrapperInput 
              label="Postal Code"
              name="postalCode"
              type="input"
              value={values.postalCode}
              onChange={(e) => setFieldValue("postalCode", e.target.value)}
            />
          </Col>
          {/* Break */}
          <Col span={24}>
            <FieldArray 
              name="address"
              render={arrayHelpers => (
                <div>
                  <div 
                    onClick={() => arrayHelpers.insert(getFieldProps("address")?.value?.length, "")}
                    className="fw-bold cs-pointer mt-2" 
                    style={{width: "120px"}}
                  >
                    <PlusCircleOutlined />
                    <span className="ml-1">Add Address</span>
                  </div>

                  {
                    values.address.map((value, index) => (
                      <AddressWrapper key={index}>
                        <FormWrapperInput 
                          label={`Address ${index + 1}`}
                          type="input"
                          value={values.address[index]}
                          onChange={(e) => setFieldValue(`address.${index}`, e.target.value)}
                        />

                        <MinusCircleOutlined onClick={() => arrayHelpers.remove(index)} />
                      </AddressWrapper>
                    ))
                  }
                </div>
              )}
            />
          </Col>
          {/* Break */}
          <Col span={24}>
            <FormWrapperInput 
              label="Gender"
              name="gender"
              type="radio"
              options={["Man", "Woman"]}
              value={values.gender}
              onChange={(e) => setFieldValue("gender", e.target.value)}
            />
          </Col>
          
          <Col span={24}>
            <Button 
              className="mt-2" 
              name="Submit" 
              htmlType="submit"
            />
          </Col>
        </Row>
      </Form>
    </FormikProvider>
  )
};

UserForm.propTypes = {
  initialValues: PropTypes.object,
  isEdit: PropTypes.bool,
  onSubmit: PropTypes.func,
};

UserForm.defaultProps = {
  initialValues: {
    userName: "",
    email: "",
    nationalId: "",
    dateOfBirth: "",
    contactNo: "",
    password: "",
    confirmPassword: "",
    city: "",
    postalCode: "",
    gender: "man",
    country: "SG",
    stage: "Singapore",
    role: "user",
    address: [],
  },
  isEdit: false,
  onSubmit: () => {}
};

export default UserForm;
