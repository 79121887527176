export const labelStatusXero = {
  "DRAFT": "Draft",
  "AUTHORISED": "Waiting Payment",
  "PAID": "Paid",
  "VOIDED": "Voided",
  "DELETED": "Deleted",
  "SUBMITTED": "Waiting Approval"
}

export const labelStatusRepeatUnit = {
  "WEEKLY": "Week(s)",
  "MONTHLY": "Month(s)"
}

export const labelStatusPaymentTerm = {
  "DAYSAFTERBILLDATE": "day(s) after bill date",
  "DAYSAFTERBILLMONTH": "day(s) after bill month",
  "OFCURRENTMONTH": "of the current month",
  "OFFOLLOWINGMONTH": "of the following month"
}

export const accountTypes = {
  Asset: {
    CURRENT: "Current Asset",
    FIXED: "Fixed Asset",
    NONCURRENT: "Non-current Asset",
    PREPAYMENT: "Prepayment"
  },
  Equity: {
    EQUITY: "Equity"
  },
  Expenses: {
    DEPRECIATN: "Depreciation",
    DIRECTCOSTS: "Direct Costs",
    EXPENSE: "Expense",
    OVERHEADS: "Overhead"
  },
  Liabilities: {
    CURRLIAB: "Current Liability",
    LIABILITY: "Liability",
    TERMLIAB: "Non-current Liability"
  },
  Revenue: {
    OTHERINCOME: "Other Income",
    REVENUE: "Revenue",
    SALES: "Sales"
  }
}

export const formatStatusXero = (status) => {
  if (labelStatusXero[status]) {
    return labelStatusXero[status];
  }

  return status;
}