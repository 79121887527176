import React, { useMemo, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Select, Tag } from "antd";
import { EyeOutlined } from '@ant-design/icons';

import PageTitleWrapper from "components/pageTitleWrapper";
import BankCard from "components/bankCard";
import DataTable from "components/dataTable";
import Button from "components/button";
import Loading from "components/loading";
import { getAccounts, getRecordKeeping } from "services/xero.service";
import { formatDMY } from "utils/formatTime";
import { thousandSepartors } from "utils/formatNumber";

const { Option} = Select;

const RecordKeepingPayment = () => {
  const navigate = useNavigate();

  const [records, setRecords] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [accountId, setAccountId] = useState("");
  const [balanceAmount, setBalanceAmount] = useState(0);
  const [statementBalance, setStatementBalance] = useState(0);
  const [finishLoadAcc, setFinishLoadAcc] = useState(false);
  const [finishLoad, setFinishLoadA] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);

  useEffect(() => {
    getAccounts({type: "BANK"})
    .then((res) => {
      setAccounts(res.accounts);
      setAccountId(res.accounts.length > 0 ? res.accounts[0].accountID : "");
      setFinishLoadAcc(true);
    })
  }, []);

  useEffect(() => {
    if (finishLoadAcc) {
      getRecordKeeping({ accountId: accountId })
      .then((res) => {
        setRecords(res.journals);
        setBalanceAmount(res.balanceAmount);
        setStatementBalance(res.statementBalance);
        setFinishLoadA(true);
      });
    }
  }, [isUpdate, finishLoadAcc]);

  const columnRecords = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (_, record) => formatDMY(record.date)
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Reference",
      dataIndex: "ref",
      key: "ref",
    },
    {
      title: "Payment Ref",
      dataIndex: "paymentRef",
      key: "paymentRef",
    },
    {
      title: "Spent",
      dataIndex: "spent",
      key: "spent",
      width: "150px",
      render: (_, record) => thousandSepartors(record.spent)
    },
    {
      title: "Received",
      dataIndex: "received",
      key: "received",
      width: "150px",
      render: (_, record) => thousandSepartors(record.received)
    },
    {
      title: "Balance",
      dataIndex: "balance",
      key: "balance",
      width: "150px",
      render: (_, record) => thousandSepartors(record.balance)
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "100px",
      render: (_, record) => {
        if (record.isReconciled === undefined)
          return "";

        if (record.isReconciled) {
          return <Tag color="green">Reconciled</Tag>
        } else {
          return <Tag color="red">UnReconciled</Tag>
        }
      }
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "80px",
      render: (_, record) => {
        return (
          <div className="icon-wrapper">
            {
              (record.type === "banktransaction" || record.type === "payment") && (
                <EyeOutlined 
                  onClick={() => {
                    if (record.type === "banktransaction") {
                      navigate(`/recordkeeping/banktransaction/${record.sourceId}`);
                    } else if (record.type === "payment") {
                      navigate(`/recordkeeping/payment/${record.sourceId}`);
                    }
                  }}
                />
              )
            }
          </div>
        )
      }
    },
  ];

  const sourceRecords = useMemo(() => {
    return records.map((record, index) => (
      {
        ...record,
        key: index
      }
    ));
  }, [records]);

  const getAccountDetail = () => {
    const selectedAccount = accounts.find(x => x.accountID === accountId);  

    return {
      name: selectedAccount?.name ?? "",
      accountNo: selectedAccount?.bankAccountNumber ?? "",
      balance: balanceAmount,
      statementBalance: statementBalance,
    }
  }

  return (
    <div className="body-wrapper">
      <PageTitleWrapper className="title-wrapper">
        <div>
          <h2>Bank Accounts - Account Transaction</h2>
        </div>
        <div>
          <Select 
            value={accountId} 
            style={{width: "150px"}} 
            onChange={(e) => setAccountId(e)}
          >
            {
              accounts.map((account, index) => (
                <Option key={index} value={account.accountID}>{account.name}</Option>
              ))
            }
          </Select>
          <Button name="Update" className="ml-2" onClick={() => setIsUpdate(!isUpdate)} />
        </div>
      </PageTitleWrapper>

      {
        !finishLoad && (
          <Loading />
        )
      }
      
      {
        finishLoad && (
          <React.Fragment>
            <BankCard 
              className="mb-3"
              bank={getAccountDetail()}
              source={{
                name: "Xero"
              }}
            />
      
            <DataTable 
              columns={columnRecords} 
              sources={sourceRecords} 
              pagination={{
                defaultPageSize: 50
              }}
              scroll={{ y: 800 }}
            />
          </React.Fragment>
        )
      }
    </div>
  );
}


export default RecordKeepingPayment;
