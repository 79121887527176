import React from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";

import { createUser } from "services/user.service";
import PageTitleWrapper from "components/pageTitleWrapper";

import UserForm from "./userForm";

const CreateUser = () => {
  const navigate = useNavigate();

  const submitUser = useMutation(values => {
    return createUser({values});
  });

  const onSubmitUser = (values) => {
    submitUser.mutate(values, {
      onSuccess: (res) => {
        if (res) {
          navigate("/admin/users");
        }
      }
    });
  }

  return (
    <div className="body-wrapper">
      <PageTitleWrapper className="title-wrapper">
        <div>
          <h2>Create User</h2>
        </div>
        <div className="search-input">
        </div>
      </PageTitleWrapper>

      <UserForm 
        onSubmit={onSubmitUser}
      />
    </div>
  )
};

export default CreateUser;
