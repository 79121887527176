import axiosReq from "utils/axiosConfig";
import { alertError, alertSuccess } from "./alert.service";

export const login = async payload => {
  try {
    const res = await axiosReq.post(`/api/auth/login`, payload);
    alertSuccess("Success");

    return res.data;
  } catch(err) {
    console.log(err)
    alertError(err.response?.data?.message ?? "Action Fail");
  }
}

export const verifyToken = async ({data}) => {
  try {
    const res = await axiosReq.post(`/api/auth/verifyToken`, data);
    alertSuccess("Success");

    return res.data;
  } catch(err) {
    console.log(err)
    alertError(err.response?.data?.message ?? "Action Fail");
  }
}

export const accessOrg = async () => {
  try {
    const res = await axiosReq.get(`/api/auth/access/organization`);
    alertSuccess("Success");

    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "Action Fail");
  }
}