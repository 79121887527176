import React, { useEffect, useMemo, useState } from "react";
import { DatePicker } from "antd";
import moment from "moment";

import PageTitleWrapper from "components/pageTitleWrapper";
import DataTable from "components/dataTable";
import Button from "components/button";
import Loading from "components/loading";
import { getBankSummary } from "services/xero.service";
import { removeSpace } from "utils/common";
import { formatDMY } from "utils/formatTime";
import { thousandSepartors } from "utils/formatNumber";

const { RangePicker } = DatePicker;

const BankSummary = ({
}) => {
  const [reportInfo, setReportInfo] = useState(null);
  const [bankSummaries, setBankSummaries] = useState([]);
  const [fromDate, setFromDate] = useState(moment());
  const [toDate, setToDate] = useState(moment())
  const [finishLoad, setFinishLoad] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);

  useEffect(() => {
    getBankSummary({
      fromDate: formatDMY(fromDate),
      toDate: formatDMY(toDate)
    })
    .then((res) => {
      if (res.reports.length > 0) {
        const report = res.reports[0];
        setReportInfo({
          reportName: report.reportTitles.length > 0 ? report.reportTitles[0] : "",
          reportOrg: report.reportTitles.length > 1 ? report.reportTitles[1] : "",
          reportDate: report.reportTitles.length > 2 ? report.reportTitles[2] : ""
        })
        setBankSummaries(report.rows);
        setFinishLoad(true);
      }
    })
  }, [isUpdate]);

  const renderColumn = (record, field) => {
    const getValue = () => {
      if (field === "BankAccounts")
        return record[field];
      else
        return thousandSepartors(record[field], 0);
    }

    return (
      <span className={record.fwbold ? "fw-bold" : ""}>{getValue()}</span>
    );
  }

  const columnBankSummaries = useMemo(() => {
    if (bankSummaries.length > 0) {
      return bankSummaries[0].cells.map((cell, cellIdx) => {
        const field = removeSpace(cell.value);

        return {
          title: cell.value,
          dataIndex: field,
          key: field,
          width: cellIdx === 0 ? "30%" : "",
          render: (_, record) => renderColumn(record, field)
        }
      })
    } 

    return [];
  }, [bankSummaries]);

  const sourceBankSummaries = useMemo(() => {
    const arr = [];
    if (columnBankSummaries.length > 0) {
      for (let i = 1; i <= bankSummaries.length - 1; i++) {
        bankSummaries[i].rows.map((row, rowIdx) => {
          // fwbold for total
          const values = { fwbold: rowIdx === bankSummaries[i].rows.length - 1 };
          columnBankSummaries.map((column, columnIdx) => {
            values[column.key] = row.cells[columnIdx].value;
          });
  
          // add values
          arr.push({
            key: `${i}-${rowIdx}`,
            ...values
          })
        })
      }

    }

    return arr;
  }, [bankSummaries, columnBankSummaries]);

  return (
    <div className="body-wrapper">
      <PageTitleWrapper className="title-wrapper">
        <div>
          <h2>{reportInfo?.reportName ?? "Bank Summary"}</h2>
          <p className="mb-0">{reportInfo?.reportOrg ?? "-"}</p>
        </div>
        <div>
          <RangePicker 
            value={[fromDate, toDate]}
            onChange={(_, dateString) => {
              setFromDate(moment(dateString[0]));
              setToDate(moment(dateString[1]));
            }}
          />
          <Button name="Update" className="ml-2" onClick={() => setIsUpdate(!isUpdate)} />
        </div>
      </PageTitleWrapper>

      {
        !finishLoad && (
          <Loading />
        )
      }

      {
        finishLoad && (
          <DataTable 
            columns={columnBankSummaries} 
            sources={sourceBankSummaries} 
            pagination={false}
          />
        )
      }
    </div>
  );
}


export default BankSummary;
