import React, { useState, useEffect } from "react";
import { useMutation } from "react-query";
import { useFormik, Form, FormikProvider } from 'formik';
import { Row, Col } from "antd";

import PageTitleWrapper from "components/pageTitleWrapper";
import FormWrapperInput from "components/formWrapperInput";
import Button from "components/button";

import { getSetting, updateSetting } from "services/setting.service";

const Setting = () => {
  const [initialValues, setInitialValues] = useState({});

  useEffect(() => {
    getSetting()
    .then((res) => {
      setInitialValues(res.data);
    })
  }, []);

  const submitSetting = useMutation(values => {
    return updateSetting({values});
  });

  const onSubmit = (values) => {
    delete values._id;

    submitSetting.mutate(values, {
      onSuccess: (res) => {
        if (res) {
          console.log(res)
        }
      }
    });
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: (values) => onSubmit(values)
  });

  const { touched, errors, values, handleSubmit, setFieldValue } = formik;

  return (
    <div className="body-wrapper">
      <PageTitleWrapper className="title-wrapper">
        <h2>Setting</h2>
      </PageTitleWrapper>

      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Row gutter={16}>
            <Col span={12}>
              <FormWrapperInput 
                label="Xero Client Id"
                name="xeroClientId"
                type="input"
                value={values.xeroClientId}
                onChange={(e) => setFieldValue("xeroClientId", e.target.value)}
                error={Boolean(touched.xeroClientId && errors.xeroClientId)}
                helpertext={touched.xeroClientId && errors.xeroClientId}
              />
            </Col>
            <Col span={12}>
              <FormWrapperInput 
                label="Xero Client Secret"
                name="xeroClientSecret"
                type="input"
                value={values.xeroClientSecret}
                onChange={(e) => setFieldValue("xeroClientSecret", e.target.value)}
                error={Boolean(touched.xeroClientSecret && errors.xeroClientSecret)}
                helpertext={touched.xeroClientSecret && errors.xeroClientSecret}
              />
            </Col>
            <Col span={12}>
              <FormWrapperInput 
                label="Xero Redirect Url"
                name="xeroRedirectUrl"
                type="input"
                value={values.xeroRedirectUrl}
                onChange={(e) => setFieldValue("xeroRedirectUrl", e.target.value)}
                error={Boolean(touched.xeroRedirectUrl && errors.xeroRedirectUrl)}
                helpertext={touched.xeroRedirectUrl && errors.xeroRedirectUrl}
              />
            </Col>
            <Col span={24}>
              <Button 
                className="mt-2" 
                name="Submit" 
                htmlType="submit"
              />
            </Col>
          </Row>
        </Form>
      </FormikProvider>
    </div>
  );
}

export default Setting;
