import styled from "styled-components";
import { Divider } from "antd";

export const StyledWrapper = styled.div`
  .infobox {
    margin-bottom: 10px;
    p {
      margin-bottom: 5px;
    }
  }
`;

export const FooterInvoice = styled.div`
  float: right;
  margin-top: 25px;
  padding-right: 25px;
  > div {
    margin-bottom: 10px;
    span:first-child {
      display: inline-block;
      min-width: 200px;
    }
  }
` ;

export const StyledDivider = styled(Divider)`
  margin: 8px 0px;
`;
