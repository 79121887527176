import React from "react";
import PropTypes from "prop-types";
import { Table } from "antd";
import styled from "styled-components";

const TableResponsive = styled.div`
  tr td {
    white-space: break-spaces;
  }

  .ant-table {
    thead > tr > th {
      background-color: ${props => props.bgColor ? props.bgColor : "none"};
      color: ${props => props.color ? props.color : "none"};
      min-width: 150px;
    }
    
    thead > tr > th:first-child {
      border-top-left-radius: 12px !important;
      min-width: 250px !important;
    }

    thead > tr > th:last-child {
      border-top-right-radius: 12px !important;
    }

    thead > tr > th:before {
      background-color: rgb(255 255 255 / 50%) !important;
    }
  }
  
  .ant-table-footer {
    padding: 0px;
  }

  .icon-wrapper {
    .anticon {
      font-size: 18px;
      cursor: pointer;
      margin-right: 20px;
      color: #219653;
    }
  }
`;

const DataTable = ({
  columns,
  sources,
  bgColor,
  color,
  ...props
}) => {
  return (
    <TableResponsive 
      color={color} 
      bgColor={bgColor} 
      // className="table-responsive"
    >
      <Table 
        columns={columns} 
        dataSource={sources} 
        {...props}
      />
    </TableResponsive>
  );
}

DataTable.propTypes = {
  columns: PropTypes.array,
  sources: PropTypes.array,
  bgColor: PropTypes.string,
  color: PropTypes.string,
};

DataTable.defaultProps = {
  columns: [],
  sources: [],
  bgColor: "#219653",
  color: "white",
};

export default DataTable;
