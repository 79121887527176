import React from "react";
import PropTypes from "prop-types";

import { thousandSepartors } from "utils/formatNumber";

import { Wrapper } from "./styled";

const BankCard = ({
  bank,
  source,
  ...props
}) => {
  return (
    <Wrapper {...props}>
      <div>
        <div className="detail-wrapper">
          <h3>{bank.name ? bank.name : ""}</h3>
          <h3>{bank.accountNo ? bank.accountNo : ""}</h3>
          <span className="fw-bold">Source : {source.name ? source.name : ""}</span>
        </div>
        <div className="balance-wrapper">
          <React.Fragment>          
            {
              bank.statementBalance != undefined && (
                <div>
                  <h3>{bank.statementBalance ? thousandSepartors(bank.statementBalance) : "0"}</h3>
                  <span>STATEMENT BALANCE</span>
                </div>
              )
            }

            {
              bank.balance !== undefined && (
                <div>
                  <h3>{bank.balance ? thousandSepartors(bank.balance) : "0"}</h3>
                  <span>BALANCE</span>
                </div>
              )
            }
          </React.Fragment>
        </div>
      </div>
    </Wrapper>
  )
};

BankCard.propTypes = {
  bank: PropTypes.object,
  source: PropTypes.object,
};

BankCard.defaultProps = {
  bank: {},
  source: {},
};

export default BankCard;
