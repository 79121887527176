import React, { useEffect, useState, useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { Input, Tree } from 'antd';
import { 
  SearchOutlined, 
  PlusOutlined, 
  EditOutlined, 
  DeleteOutlined, 
  // SafetyCertificateOutlined 
} from '@ant-design/icons';

import PageTitleWrapper from "components/pageTitleWrapper";
import DataTable from "components/dataTable";
import { menuUsers } from "components/layout/Sidenav";
import DefaultModal from "components/defaultModal";
import { openConfirmation } from "components/confirmationModal";
import { getUsers, updateUser, deleteUser } from "services/user.service";

const Users = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [openAssign, setOpenAssign] = useState(false);
  const [checked, setChecked] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const searchValue = useRef();
  const searchButton = useRef();

  useEffect(() => {
    getUsers()
    .then((res) => {
      setUsers(res.data);
    })
  }, []);

  const getTreeCheckboxs = () => {
    const arr = [];

    menuUsers.map((parentMenu, parentIdx) => {
      arr.push({
        title: parentMenu.label,
        key: `${parentIdx}`,
        children: parentMenu.children ? getChildMenus(parentMenu.children, parentIdx) : []
      });

      return false;
    });

    return arr;
  }

  const getChildMenus = (childMenus, parentIdx) => {
    const arr = [];
    
    childMenus.map((childMenu, childIdx) => {
      arr.push({
        title: childMenu.label,
        key: `${parentIdx}-${childIdx}`,
        children: childMenu.children ? getChildMenus(childMenu.children, `${parentIdx}-${childIdx}`) : []
      });

      return false;
    });

    return arr;
  }

  const columnUsers = [
    {
      title: "Username",
      dataIndex: "userName",
      key: "userName",
      filterDropdown: ({ setSelectedKeys, confirm }) => (
        <React.Fragment>
          <Input 
            ref={searchValue}
            name="searchName"
          />
          <button 
            ref={searchButton}
            onClick={() => {
              setSelectedKeys(searchValue?.current?.input?.value ? [searchValue.current.input.value] : []);
              confirm();
            }} 
          >
            Search
          </button>
        </React.Fragment>
      ),
      filterDropdownVisible: true,
      onFilter: (value, record) => record.userName.toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "ContactNo",
      dataIndex: "contactNo",
      key: "contactNo",
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      render: (_, record) => {
        return (
          <div className="icon-wrapper">
            {/* <SafetyCertificateOutlined 
              onClick={() => openAssignPermission(record)}
            /> */}
            <EditOutlined 
              onClick={() => navigate(`/admin/users/edit/${record.uId}`)}
            />
            <DeleteOutlined 
              onClick={() => openConfirmation({
                title: "Are you sure want to delete this data ?",
                onOk: () => handleDelete(record.uId)
              })}
            />
          </div>
        )
      }
    },
  ];

  const sourceusers = useMemo(() => {
    return users.map((user, index) => {
      return {
        key: index,
        _id: user._id,
        uId: user.uId,
        userName: user.userName,
        email: user.email,
        contactNo: user.contactNo,
        permissions: user.permissions ? user.permissions : []
      }
    })
  }, [users]);

  const getPageFromKey = (keys) => {
    let selectedMenu = [...menuUsers];
    keys.map((selectedKey, index) => {
      if (index === 0) {
        selectedMenu = selectedMenu[selectedKey];
      } else {
        selectedMenu = selectedMenu.children[selectedKey];
      }

      return false;
    });

    return selectedMenu;
  }

  // const getPermissionKeys = (permissions) => {
  //   return permissions.map((permission) => {
  //     let checkedKey = "";
  //     menuUsers.map((menuUser, parentIdx) => {
  //       if (checkedKey === "") {
  //         if (menuUser.key === permission.key) {
  //           checkedKey = parentIdx.toString();
  //           return false;
  //         }
          
  //         menuUser.children?.map((children, childIdx) => {
  //           if (children.key === permission.key) {
  //             checkedKey = `${parentIdx}-${childIdx}`;
  //             return false;
  //           }
  //         });
  //       }

  //       return false;
  //     })

  //     return checkedKey;
  //   })
  // }

  const submitUser = useMutation(values => {
    return updateUser({values, userId: selectedRecord.uId});
  });

  // const openAssignPermission = (record) => {
  //   setChecked(getPermissionKeys(record.permissions));
  //   setSelectedRecord(record);
  //   setOpenAssign(true);
  // }

  const onAssignPermission = () => {
    const selectedPages = [];
    checked.map((checkedKey) => {
      const splitKey = checkedKey.split("-");
      selectedPages.push(getPageFromKey(splitKey));

      return false;
    });

    const values = {
      _id: selectedRecord._id,
      uId: selectedRecord.uId,
      email: selectedRecord.email,
      permissions: selectedPages
    };

    submitUser.mutate(values, {
      onSuccess: (res) => {
        if (res) {
          setChecked([]);
          setSelectedRecord(null);
          setUsers(() => {
            const prevValues = [...users];
            const selectedUser = prevValues.find(x => x.uId === selectedRecord.uId);
            if (selectedUser) {
              selectedUser.permissions = selectedPages;
            }

            return prevValues;
          });
          setOpenAssign(false);
        }
      }
    });
  }

  const handleDelete = async (deleteId) => {
    await deleteUser({deleteId});
    setUsers(users.filter(x => x.uId !== deleteId));
  }

  return (
    <div className="body-wrapper">
      <PageTitleWrapper className="title-wrapper">
        <div>
          <h2>Users</h2>
        </div>
        <div className="search-input">
          <SearchOutlined />
          <Input
            placeholder="Search..."
            onChange={(e) => {
              if (searchValue?.current) {
                searchValue.current.input.value = e.target.value
                searchButton.current.click();
              }
            }}
            style={{
              width: 200,
            }}
          />
          <PlusOutlined onClick={() => navigate("/admin/users/create")} />
        </div>
      </PageTitleWrapper>

      <DataTable 
        columns={columnUsers} 
        sources={sourceusers} 
        pagination={true}
      />

      <DefaultModal
        open={openAssign}
        title="Assign Permission"
        handleOk={() => onAssignPermission()}
        handleClose={() => setOpenAssign(!openAssign)}
      >
        <Tree
          checkable
          checkedKeys={checked}
          onCheck={(val) => setChecked(val)}
          treeData={getTreeCheckboxs()}
        />
      </DefaultModal>
    </div>
  );
}

export default Users;
