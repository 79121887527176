import React, { useMemo } from "react";
import PropTypes from "prop-types";
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { useMutation } from "react-query";
import { Checkbox } from "antd";
import { isEmpty } from "lodash";

import DefaultModal from "components/defaultModal";
import FormWrapperInput from "components/formWrapperInput";
import { createItem } from "services/xero.service";

import { InlineBox, StyledDivider } from "./styled";

const ModalNewItem = ({
  open,
  setOpen,
  setShowNewAccount,
  accounts,
  taxRates,
  refetchItem,
}) => {
  let accountSchema = Yup.object().shape({
    code: Yup.string().required('Item code is required'),
  });

  const submitItem = useMutation(values => {
    return createItem({
      data: {
        items: [values]
      }
    })
  })

  const onSubmitItem = (values) => {
    submitItem.mutate(values, {
      onSuccess: (res) => {
        refetchItem();
        setOpen(false);
      }
    });
  }

  const formik = useFormik({
    initialValues: {
      code: "",
      name: "",
      isSold: false,
      isPurchased: false,
      inventoryAssetAccountCode: "",
    },
    validationSchema: accountSchema,
    enableReinitialize: true,
    onSubmit: (values) => onSubmitItem(values)
  });

  const { errors, touched, values, handleSubmit, setFieldValue, getFieldProps } = formik;

  const optionAccounts = useMemo(() => {
    if (isEmpty(accounts)) {
      return [];
    }

    return Object.keys(accounts).map((key) => (
      {
        label: key,
        children: accounts[key].map((child) => (
          {
            label: child.name,
            value: child.code
          }
        ))
      }
    ))
  }, [accounts]);

  const getFilterTaxRates = (type) => {
    let optionTaxs = [...taxRates];
    let accountCode = "";
    if (type === "isPurchased") {
      accountCode = getFieldProps(`purchaseDetails.accountCode`).value;
    }
    if (type === "isSold") {
      accountCode = getFieldProps(`salesDetails.accountCode`).value;
    }
    if (type === "isCost") {
      accountCode = getFieldProps(`purchaseDetails.cOGSAccountCode`).value;
    }

    const mergedAccounts = [].concat.apply([], Object.values(accounts))
    const selectedAccount = mergedAccounts.find(x => x.code === accountCode);
    if (selectedAccount) {
      if (selectedAccount._class === "ASSET") {
        optionTaxs = taxRates.filter(x => x.canApplyToAssets === true);
      }
      if (selectedAccount._class === "EQUITY") {
        optionTaxs = taxRates.filter(x => x.canApplyToEquity === true);
      }
      if (selectedAccount._class === "EXPENSE") {
        optionTaxs = taxRates.filter(x => x.canApplyToExpenses === true);
      }
      if (selectedAccount._class === "LIABILITY") {
        optionTaxs = taxRates.filter(x => x.canApplyToLiabilities === true);
      }
      if (selectedAccount._class === "REVENUE") {
        optionTaxs = taxRates.filter(x => x.canApplyToRevenue === true);
      }
    }

    return optionTaxs.map(x => (
      {
        label: `${x.name} (${x.displayTaxRate}%)`,
        value: x.taxType
      }
    ));
  }

  const getTaxType = (value) => {
    const mergedAccounts = [].concat.apply([], Object.values(accounts))
    const selectedAccount = mergedAccounts.find(x => x.code === value);
    if (selectedAccount) {
      return selectedAccount.taxType;
    }

    return "";
  }

  return (
    <DefaultModal
      open={open}
      title="New Item"
      width="568px"
      handleOk={handleSubmit}
      handleClose={() => setOpen(false)}
    >
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <InlineBox width="50%">
            <FormWrapperInput 
              label="Item Code"
              name="code"
              type="input"
              value={values.code}
              onChange={(e) => setFieldValue("code", e.target.value)}
              error={Boolean(touched.code && errors.code)}
              helpertext={touched.code && errors.code}
            />
          </InlineBox>
          <InlineBox width="50%">
            <FormWrapperInput 
              label="Item Name"
              name="name"
              type="input"
              value={values.name}
              onChange={(e) => setFieldValue("name", e.target.value)}
            />
          </InlineBox>

          <div className="mt-2">
            <Checkbox 
              checked={values.isPurchased} 
              disabled={values.isTrack || values.inventoryAssetAccountCode !== ""}
              onChange={e => setFieldValue("isPurchased", e.target.checked)}
            >
              I purchase this item
            </Checkbox>

            {
              values.isPurchased && (
                <div>
                  <InlineBox width="120px">
                    <FormWrapperInput 
                      label="Unit Price"
                      name="purchaseDetails.unitPrice"
                      type="number"
                      value={values.purchaseDetails?.unitPrice}
                      onChange={(e) => setFieldValue("purchaseDetails.unitPrice", e.target.value)}
                    />
                  </InlineBox>
                  {
                    (values.isTrack || values.inventoryAssetAccountCode !== "") ? (
                      <InlineBox width="200px">
                        <FormWrapperInput 
                          label="Purchase Account"
                          name="purchaseDetails.cOGSAccountCode"
                          type="select"
                          className="group-option"
                          value={values.purchaseDetails?.cOGSAccountCode}
                          options={optionAccounts}
                          isNewOption={true}
                          isGroupOption={true}
                          setOpen={setShowNewAccount}
                          onChange={(e) => {
                            setFieldValue("purchaseDetails.cOGSAccountCode", e)
                            setFieldValue(`purchaseDetails.taxType`, getTaxType(e));
                          }}
                        />
                      </InlineBox>
                    ) : (
                      <InlineBox width="200px">
                        <FormWrapperInput 
                          label="Purchase Account"
                          name="purchaseDetails.accountCode"
                          type="select"
                          className="group-option"
                          value={values.purchaseDetails?.accountCode}
                          options={optionAccounts}
                          isNewOption={true}
                          isGroupOption={true}
                          setOpen={setShowNewAccount}
                          onChange={(e) => {
                            setFieldValue("purchaseDetails.accountCode", e)
                            setFieldValue(`purchaseDetails.taxType`, getTaxType(e));
                          }}
                        />
                      </InlineBox>
                    )
                  }
                  <InlineBox width="200px">
                    <FormWrapperInput 
                      label="Tax Rate"
                      name="purchaseDetails.taxType"
                      type="select"
                      value={values.purchaseDetails?.taxType}
                      options={getFilterTaxRates((values.isTrack || values.inventoryAssetAccountCode !== "") ? "isCost" : "isPurchased")}
                      onChange={(e) => setFieldValue("purchaseDetails.taxType", e)}
                    />
                  </InlineBox>
                  <InlineBox width="100%">
                    <FormWrapperInput 
                      label="Description"
                      name="purchaseDescription"
                      type="textarea"
                      value={values.purchaseDescription}
                      onChange={(e) => setFieldValue("purchaseDescription", e.target.value)}
                    />
                  </InlineBox>
                </div>
              )
            }
          </div>

          <StyledDivider style={{margin: "15px 0px"}} />

          <div>
            <Checkbox 
              checked={values.isSold} 
              disabled={values.isTrack || values.inventoryAssetAccountCode !== ""}
              onChange={e => setFieldValue("isSold", e.target.checked)}
            >
              I sell this item
            </Checkbox>

            {
              values.isSold && (
                <div>
                  <InlineBox width="120px">
                    <FormWrapperInput 
                      label="Unit Price"
                      name="salesDetails.unitPrice"
                      type="input"
                      value={values.salesDetails?.unitPrice}
                      onChange={(e) => setFieldValue("salesDetails.unitPrice", e.target.value)}
                    />
                  </InlineBox>
                  <InlineBox width="200px">
                    <FormWrapperInput 
                      label="Sales Account"
                      name="salesDetails.accountCode"
                      type="select"
                      className="group-option"
                      value={values.salesDetails?.accountCode}
                      options={optionAccounts}
                      isNewOption={true}
                      isGroupOption={true}
                      setOpen={setShowNewAccount}
                      onChange={(e) => {
                        setFieldValue("salesDetails.accountCode", e)
                        setFieldValue(`salesDetails.taxType`, getTaxType(e));
                      }}
                    />
                  </InlineBox>
                  <InlineBox width="200px">
                    <FormWrapperInput 
                      label="Tax Rate"
                      name="salesDetails.taxType"
                      type="select"
                      value={getFieldProps("salesDetails.taxType")?.value}
                      options={getFilterTaxRates("isSold")}
                      onChange={(e) => setFieldValue("salesDetails.taxType", e)}
                    />
                  </InlineBox>
                  <InlineBox width="100%">
                    <FormWrapperInput 
                      label="Description"
                      name="description"
                      type="textarea"
                      value={values.description}
                      onChange={(e) => setFieldValue("description", e.target.value)}
                    />
                  </InlineBox>
                </div>
              )
            }
          </div>

          <StyledDivider style={{margin: "15px 0px"}} />

          <div>
            <Checkbox 
              onChange={e => {
                setFieldValue("isPurchased", e.target.checked);
                setFieldValue("iSold", e.target.checked);
                setFieldValue("isTrack", e.target.checked);
              }}
          >
              I track this item
            </Checkbox>

            {
              values.isTrack && (
                <div>
                  <InlineBox width="200px">
                    <FormWrapperInput 
                      label="Inventory Asset Account"
                      name="inventoryAssetAccountCode"
                      type="select"
                      className="group-option"
                      value={values.inventoryAssetAccountCode}
                      options={optionAccounts}
                      isNewOption={true}
                      isGroupOption={true}
                      setOpen={setShowNewAccount}
                      onChange={(e) => setFieldValue("inventoryAssetAccountCode", e)}
                    />
                  </InlineBox>
                </div>
              )
            }
          </div>
        </Form>
      </FormikProvider>
    </DefaultModal>
  )
};

ModalNewItem.propTypes = {
  open: PropTypes.bool,
  accounts: PropTypes.object,
  taxRates: PropTypes.array,
  setOpen: PropTypes.func,
  setShowNewAccount: PropTypes.func,
  refetchItem: PropTypes.func,
};

ModalNewItem.defaultProps = {
  open: false,
  accounts: {},
  taxRates: [],
  setOpen: () => {},
  setShowNewAccount: () => {},
  refetchItem: () => {},
};

export default ModalNewItem;
