import axiosReq from "utils/axiosConfig";
import { alertError, alertSuccess } from "./alert.service";

export const connect = async () => {
  try {
    const res = await axiosReq.get(`/api/xero/connect`);
    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "Action Fail");
  }
}

export const callback = async ({search}) => {
  try {
    const res = await axiosReq.get(`/api/xero/callback${search}`);
    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "Action Fail");
  }
}

export const getAccounts = async ({type}) => {
  try {
    const res = await axiosReq.get(`/api/xero/accounts`, {
      params: {
        type: type
      }
    });
    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "Action Fail");
  }
}

export const getContacts = async () => {
  try {
    const res = await axiosReq.get(`/api/xero/contacts`);
    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "Action Fail");
  }
}

export const getItems = async () => {
  try {
    const res = await axiosReq.get(`/api/xero/items`);
    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "Action Fail");
  }
}

export const getTaxRates = async () => {
  try {
    const res = await axiosReq.get(`/api/xero/taxRates`);
    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "Action Fail");
  }
}

export const getTrackingCategories = async () => {
  try {
    const res = await axiosReq.get(`/api/xero/trackingcategories`);
    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "Action Fail");
  }
}

export const getCurrencies = async () => {
  try {
    const res = await axiosReq.get(`/api/xero/currencies`);
    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "Action Fail");
  }
}

export const getTrialBalance = async ({ date }) => {
  try {
    const res = await axiosReq.get(`/api/xero/trialbalance`, {
      params: {
        date: date
      }
    });
    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "Action Fail");
  }
}

export const getBankSummary = async ({ fromDate, toDate }) => {
  try {
    const res = await axiosReq.get(`/api/xero/banksummary`, {
      params: {
        fromDate: fromDate,
        toDate: toDate
      }
    });
    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "Action Fail");
  }
}

export const getProfitLoss = async ({ queryKey }) => {
  try {
    const { 
      fromDate,
      toDate,
      period,
      timeFrame,
      layout,
      paymentOnly
    } = queryKey.length > 1 ? queryKey[1] : {};

    const res = await axiosReq.get(`/api/xero/profitloss`, {
      params: {
        fromDate: fromDate,
        toDate: toDate,
        period: period,
        timeFrame: timeFrame,
        layout: layout,
        paymentOnly: paymentOnly
      }
    });
    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "Action Fail");
  }
}

export const getRecordKeeping = async ({ accountId }) => {
  try {
    const res = await axiosReq.get(`/api/xero/recordkeeping`, {
      params: {
        accountId: accountId
      }
    });
    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "Action Fail");
  }
}


export const getCashflow = async () => {
  try {
    const res = await axiosReq.get(`/api/xero/cashflow`);
    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "Action Fail");
  }
}

export const getBankReconciliation = async ({ accountId }) => {
  try {
    const res = await axiosReq.get(`/api/xero/bankreconcil`, {
      params: {
        accountId: accountId
      }
    });
    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "Action Fail");
  }
}

export const getInvoices = async ({ queryKey }) => {
  try {
    const { status } = queryKey.length > 1 ? queryKey[1] : {};
    const res = await axiosReq.get(`/api/xero/invoices`, {
      params: {
        status: status
      }
    });
    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "Action Fail");
  }
}

export const getInvoice = async ({ queryKey }) => {
  try {
    const { invoiceId } = queryKey.length > 1 ? queryKey[1] : {};
    const res = await axiosReq.get(`/api/xero/invoices/${invoiceId}`);
    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "Action Fail");
  }
}

export const getInvoiceAsPdf = async ({ invoiceId }) => {
  try {
    const res = await axiosReq.get(`/api/xero/invoices/pdf/${invoiceId}`, {
      responseType: "blob"
    });
    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "Action Fail");
  }
}

export const getRepeatInvoices = async ({ queryKey }) => {
  try {
    const { status } = queryKey.length > 1 ? queryKey[1] : {};
    const res = await axiosReq.get(`/api/xero/repeat/invoices`, {
      params: {
        status: status
      }
    });
    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "Action Fail");
  }
}

export const getRepeatInvoice = async ({ queryKey }) => {
  try {
    const { invoiceId } = queryKey.length > 1 ? queryKey[1] : {};
    const res = await axiosReq.get(`/api/xero/repeat/invoices/${invoiceId}`);
    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "Action Fail");
  }
}

export const getBankTransaction = async ({ queryKey }) => {
  try {
    const { bankTransactionId } = queryKey.length > 1 ? queryKey[1] : {};
    const res = await axiosReq.get(`/api/xero/banktransaction/${bankTransactionId}`);
    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "Action Fail");
  }
}

export const getPayment = async ({ queryKey }) => {
  try {
    const { paymentId } = queryKey.length > 1 ? queryKey[1] : {};
    const res = await axiosReq.get(`/api/xero/payment/${paymentId}`);
    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "Action Fail");
  }
}

export const getClaim = async ({ queryKey }) => {
  try {
    const { claimId } = queryKey.length > 1 ? queryKey[1] : {};
    const res = await axiosReq.get(`/api/xero/claim/${claimId}`);
    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "Action Fail");
  }
}

export const createItem = async({ data }) => {
  try {
    const res = await axiosReq.post(`/api/xero/items`, data);
    alertSuccess("Success");

    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "Action Fail");
  }
}

export const createAccount = async({ data }) => {
  try {
    const res = await axiosReq.post(`/api/xero/accounts`, data);
    alertSuccess("Success");

    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "Action Fail");
  }
}

export const updateContact = async({ data, contactId }) => {
  try {
    const res = await axiosReq.put(`/api/xero/contacts/${contactId}`, data);
    alertSuccess("Success");

    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "Action Fail");
  }
}

export const createTaxRate = async({ data }) => {
  try {
    const res = await axiosReq.post(`/api/xero/taxrates`, data);
    alertSuccess("Success");

    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "Action Fail");
  }
}

export const createTracking = async({ data }) => {
  try {
    const res = await axiosReq.post(`/api/xero/trackingcategories`, data);
    alertSuccess("Success");

    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "Action Fail");
  }
}

export const createInvoice = async({ data }) => {
  try {
    const res = await axiosReq.post(`/api/xero/invoices`, data);
    alertSuccess("Success");

    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "Action Fail");
  }
}

export const updateInvoice = async({ data, invoiceId }) => {
  try {
    const res = await axiosReq.put(`/api/xero/invoices/${invoiceId}`, data);
    alertSuccess("Success");

    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "Action Fail");
  }
}

export const createRepeatingInvoice = async({ data }) => {
  try {
    const res = await axiosReq.post(`/api/xero/repeat/invoices`, data);
    alertSuccess("Success");

    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "Action Fail");
  }
}

export const updateRepeatingInvoice = async({ data, invoiceId }) => {
  try {
    const res = await axiosReq.put(`/api/xero/repeat/invoices/${invoiceId}`, data);
    alertSuccess("Success");

    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "Action Fail");
  }
}

export const createPayment = async({ data }) => {
  try {
    const res = await axiosReq.post(`/api/xero/invoices/payment`, data);
    alertSuccess("Success");

    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "Action Fail");
  }
}

export const sendInvoice = async({ data }) => {
  try {
    const res = await axiosReq.post(`/api/xero/invoices/email`, data);
    alertSuccess("Success");

    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "Action Fail");
  }
}

export const getAgedPayables = async ({ fromDate, toDate, startIndex, stopIndex, search }) => {
  try {
    const res = await axiosReq.get(`/api/xero/payables`, {
      params: {
        fromDate: fromDate,
        toDate: toDate,
        startIndex: startIndex,
        stopIndex: stopIndex,
        search: search
      },
    });
    return res.data;
  } catch(err) {
    if (err.code !== "ERR_CANCELED") {
      alertError(err.response?.data?.message ?? "Action Fail");
    }
  }
}

export const getAgedReceiveables = async ({ fromDate, toDate, startIndex, stopIndex, search }) => {
  try {
    const res = await axiosReq.get(`/api/xero/receiveables`, {
      params: {
        fromDate: fromDate,
        toDate: toDate,
        startIndex: startIndex,
        stopIndex: stopIndex,
        search: search
      },
    });
    return res.data;
  } catch(err) {
    if (err.code !== "ERR_CANCELED") {
      alertError(err.response?.data?.message ?? "Action Fail");
    }
  }
}