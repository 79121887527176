import axiosReq from "utils/axiosConfig";
import { alertError, alertSuccess } from "./alert.service";

export const getSetting = async () => {
  try {
    const res = await axiosReq.get(`/api/setting`);
    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "Fail");
  }
}

export const updateSetting = async ({values}) => {
  try {
    const res = await axiosReq.put(`/api/setting`, values);
    alertSuccess("Success");

    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "Fail");
  }
}