import React, { useMemo } from "react";
import * as Yup from 'yup';
import PropTypes from "prop-types";
import { useFormik, Form, FormikProvider } from 'formik';
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";
import { Row, Col } from "antd";

import FormWrapperInput from "components/formWrapperInput";
import Button from "components/button";
import { formatInputDate } from "utils/formatTime";
import { createPayment } from "services/xero.service";

import { InlineBox } from "./styled";

const PaymentForm = ({
  invoiceId,
  total,
  accounts,
  setShowEmailInvoice,
  setInvoiceField,
}) => {
  const navigate = useNavigate();

  const optionAccounts = useMemo(() => {
    if (isEmpty(accounts)) {
      return [];
    }

    const filteredValues = Object.keys(accounts).map((key) => (
      {
        label: key,
        children: accounts[key].filter(x => x.enablePaymentsToAccount === true || x.type === "BANK").map((child) => (
          {
            label: child.name,
            value: child.accountID
          }
        ))
      }
    ))

    return filteredValues.filter(x => x.children.length > 0);
  }, [accounts]);

  let paymentSchema = Yup.object().shape({
    account: Yup.object().shape({
      accountID: Yup.string().required('Paid To is required')
    }),
  });

  const submitPayment = useMutation(values => {
    return createPayment({
      data: values
    })
  })

  const onSubmitPayment = (values) => {
    submitPayment.mutate(values, {
      onSuccess: (res) => {
        navigate("/recordkeeping/invoice");
      }
    });
  }

  const formik = useFormik({
    initialValues: {
      invoice: {
        invoiceID: invoiceId
      },
      account: {
        accountID: "" 
      },
      amount: total,
      date: "",
      reference: ""
    },
    validationSchema: paymentSchema,
    enableReinitialize: true,
    onSubmit: (values) => onSubmitPayment(values)
  });

  const { touched, errors, values, handleSubmit, setFieldValue } = formik;

  return (
    <FormikProvider value={formik}>
      <Form className="mt-2" autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Row gutter={16}>
          <Col span={24}>
            <p className="fw-bold" style={{fontSize: "16px"}}>Payment</p>
            <InlineBox width="160px">
              <FormWrapperInput 
                label="Amount"
                name="amount"
                type="number"
                value={values.amount}
                onChange={(e) => setFieldValue("amount", e.target.value)}
              />
            </InlineBox>
            <InlineBox>
              <FormWrapperInput 
                label="Date"
                name="date"
                type="date"
                value={values.date ? formatInputDate(values.date) : ""}
                onChange={(_, dateString) => setFieldValue("date", dateString)}
              />
            </InlineBox>
            <InlineBox>
              <FormWrapperInput 
                label="Paid To"
                name="account.accountID"
                type="select"
                className="group-option"
                value={values.account?.accountID}
                options={optionAccounts}
                isGroupOption={true}
                onChange={(e) => setFieldValue("account.accountID", e)}
                error={Boolean(touched.account && errors.account)}
                helpertext={touched.account && errors.account}
              />
            </InlineBox>
            <InlineBox>
              <FormWrapperInput 
                label="Reference"
                name="reference"
                type="input"
                value={values.reference}
                onChange={(e) => setFieldValue("reference", e.target.value)}
              />
            </InlineBox>
          </Col>
          <Col span={24}>
            <Button 
              className="mt-2 mr-2" 
              name="Email" 
              onClick={() => {
                setInvoiceField("approvedForSending", true);
                setShowEmailInvoice(true);
              }}
              isDefault={true}
              type="button"
            />

            <Button 
              className="mt-2 mr-2" 
              name="Add Payment" 
              htmlType="submit"
            />
          </Col>
        </Row>
      </Form>
    </FormikProvider>
  )
};

PaymentForm.propTypes = {
  invoiceId: PropTypes.string,
  total: PropTypes.number,
  accounts: PropTypes.object,
  setShowEmailInvoice: PropTypes.func,
};

PaymentForm.defaultProps = {
  invoiceId: "",
  total: 0,
  accounts: {},
  setShowEmailInvoice: () => {}
};

export default PaymentForm;
