import { Divider, Space, Select, Input } from "antd";
import styled from "styled-components";

export const InlineBox = styled.div`
  width: ${props => props.width ? props.width : "350px"};
  display: inline-flex;
  padding-right: 15px;

  > div {
    width: 100%;
  }
`;

export const StyledDivider = styled(Divider)`
  margin: 8px 0px;
`;

export const SpaceNewItem = styled(Space)`
  cursor: pointer;
  padding: 4px 15px;
  color: #1a73e8;
`;

export const SelectInTable = styled(Select)`
  width: 100%;
  padding: 0px;

  .ant-select-selector {
    padding: 0px !important;
  }
`;

export const InputInTable = styled(Input)`
  border: 0px !important;
  padding: 0px;
`;

export const VerticalCheckbox = styled.div`
  .ant-checkbox-wrapper {
    display: flex;
    margin-bottom: 5px;
  }

  .ant-checkbox-wrapper+.ant-checkbox-wrapper {
    margin-left: 0px;
  }
`
export const StyledDataTable = styled.div`
  .ant-table-tbody > tr > td, .ant-table-thead > tr > th {
    padding: 16px 15px !important;
  }
`;

export const FooterInvoice = styled.div`
  float: right;
  padding-right: 25px;
  > div {
    margin-bottom: 10px;
    span:first-child {
      display: inline-block;
      min-width: 200px;
    }
  }
` ;

export const WrapperPrinter = styled.div`
  span {
    font-size: 22px;
    cursor: pointer;
  }
`;