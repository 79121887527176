import React from "react";
import { Routes, Route } from "react-router-dom";

// Admin
import User from "pages/admin/user";
import CreateUser from "pages/admin/user/create";
import EditUser from "pages/admin/user/edit";
import Setting from "pages/admin/setting";

// User
import Login from "pages/login";
import JumpAuthorize from "pages/jumpAuthorize";
import Home from "pages/user/home";
import MyAccount from "pages/profile/account";
import ChangePassword from "pages/profile/password";
import XeroCallback from "pages/user/xeroCallback";
import TrialBalance from "pages/user/trialBalance";
import ProfitLoss from "pages/user/profitloss";
import BankSummary from "pages/user/banksummary";
import RecordKeepingPayment from "pages/user/recordKeepingPayment";
import BankTransactionDetail from "pages/user/recordKeepingPayment/bankTransactionDetail";
import PaymentDetail from "pages/user/recordKeepingPayment/paymentDetail";
import RecordKeepingInvoice from "pages/user/recordKeepingInvoice";
import CreateInvoice from "pages/user/recordKeepingInvoice/createInvoice";
import BankReconciliation from "pages/user/bankReconciliation";
import Cashflow from "pages/user/cashflow";
import PayablesSummary from "pages/user/payablesSummary";
import ReceiveablesSummary from "pages/user/receiveablesSummary";

import AdminProtectedRoute from "./admin.route";
import UserProtectedRoute from "./user.route";

const AppRoute = () => {
  return (
    <Routes>
        <Route 
          exact 
          path="/login" 
          element={<Login />} 
        />
        <Route 
          exact 
          path="/middlepage" 
          element={
            <JumpAuthorize />
          } 
        />

        {/* Admin */}
        <Route 
          exact 
          path="/admin/users" 
          element={
            <AdminProtectedRoute>
              <User />
            </AdminProtectedRoute>
          } 
        />
        <Route 
          exact 
          path="/admin/users/create" 
          element={
            <AdminProtectedRoute>
              <CreateUser />
            </AdminProtectedRoute>
          } 
        />
        <Route 
          exact 
          path="/admin/users/edit/:id" 
          element={
            <AdminProtectedRoute>
              <EditUser />
            </AdminProtectedRoute>
          } 
        />
        <Route 
          exact 
          path="/admin/setting" 
          element={
            <AdminProtectedRoute>
              <Setting />
            </AdminProtectedRoute>
          } 
        />

        {/* Userr */}
        <Route 
          exact 
          path="/" 
          element={
            <UserProtectedRoute>
              <Home />
            </UserProtectedRoute>
          } 
        />
        <Route 
          exact 
          path="/account" 
          element={
            <UserProtectedRoute>
              <MyAccount />
            </UserProtectedRoute>
          } 
        />
        <Route 
          exact 
          path="/account/password" 
          element={
            <UserProtectedRoute>
              <ChangePassword />
            </UserProtectedRoute>
          } 
        />
        <Route 
          exact 
          path="/xero/callback" 
          element={
            <UserProtectedRoute>
              <XeroCallback />
            </UserProtectedRoute>
          } 
        />
        <Route 
          exact 
          path="/bookkeeping/trialbalance" 
          element={
            <UserProtectedRoute>
              <TrialBalance />
            </UserProtectedRoute>
          } 
        />
        <Route 
          exact 
          path="/bookkeeping/profitloss" 
          element={
            <UserProtectedRoute>
              <ProfitLoss />
            </UserProtectedRoute>
          } 
        />
        <Route 
          exact 
          path="/bookkeeping/banksummary" 
          element={
            <UserProtectedRoute>
              <BankSummary />
            </UserProtectedRoute>
          } 
        />
        <Route 
          exact 
          path="/recordkeeping/invoice" 
          element={
            <UserProtectedRoute>
              <RecordKeepingInvoice />
            </UserProtectedRoute>
          } 
        />
        <Route 
          exact 
          path="/recordkeeping/invoice/:id" 
          element={
            <UserProtectedRoute>
              <CreateInvoice />
            </UserProtectedRoute>
          } 
        />
        <Route 
          exact 
          path="/recordkeeping/repeat-invoice/:id" 
          element={
            <UserProtectedRoute>
              <CreateInvoice />
            </UserProtectedRoute>
          } 
        />
        <Route 
          exact 
          path="/recordkeeping/create-invoice" 
          element={
            <UserProtectedRoute>
              <CreateInvoice />
            </UserProtectedRoute>
          } 
        />
        <Route 
          exact 
          path="/recordkeeping/payment" 
          element={
            <UserProtectedRoute>
              <RecordKeepingPayment />
            </UserProtectedRoute>
          } 
        />
        <Route 
          exact 
          path="/recordkeeping/banktransaction/:id" 
          element={
            <UserProtectedRoute>
              <BankTransactionDetail />
            </UserProtectedRoute>
          } 
        />
        <Route 
          exact 
          path="/recordkeeping/payment/:id" 
          element={
            <UserProtectedRoute>
              <PaymentDetail />
            </UserProtectedRoute>
          } 
        />
        <Route 
          exact 
          path="/bankreconciliation" 
          element={
            <UserProtectedRoute>
              <BankReconciliation />
            </UserProtectedRoute>
          } 
        />
        <Route 
          exact 
          path="/cashflow" 
          element={
            <UserProtectedRoute>
              <Cashflow />
            </UserProtectedRoute>
          } 
        />
        <Route 
          exact 
          path="/payablesSummary" 
          element={
            <UserProtectedRoute>
              <PayablesSummary />
            </UserProtectedRoute>
          } 
        />
        <Route 
          exact 
          path="/receiveablesSummary" 
          element={
            <UserProtectedRoute>
              <ReceiveablesSummary />
            </UserProtectedRoute>
          } 
        />
    </Routes>
  );
}

export default AppRoute;
