import React from "react";
import PropTypes from "prop-types";
import * as Yup from 'yup';
import { useMutation } from "react-query";
import { useFormik, Form, FormikProvider } from 'formik';
import { Checkbox } from "antd";

import DefaultModal from "components/defaultModal";
import FormWrapperInput from "components/formWrapperInput";
import { accountTypes } from "utils/formatStatus";
import { createAccount } from "services/xero.service";

import { VerticalCheckbox } from "./styled";

const optionAccountTypes = Object.keys(accountTypes).map(key => {
  const grpOpt = {};
  grpOpt.label = key;
  grpOpt.children = Object.keys(accountTypes[key]).map((childKey) => (
    {
      label: accountTypes[key][childKey],
      value: childKey
    }
  ))

  return grpOpt;
});

const ModalNewAccount = ({
  open,
  setOpen,
  taxRates,
  refetchAccount,
}) => {
  let accountSchema = Yup.object().shape({
    type: Yup.string().required('Type is required'),
    code: Yup.string().required('Code is required'),
    name: Yup.string().required('Name is required'),
  });

  const submitAccount = useMutation(values => {
    return createAccount({
      data: values
    })
  })

  const onSubmitAccount = (values) => {
    submitAccount.mutate(values, {
      onSuccess: (res) => {
        refetchAccount();
        setOpen(false);
      }
    });
  }

  const formik = useFormik({
    initialValues: {
      type: "",
      code: "",
      name: "",
      description: "",
      taxType: "",
      addToWatchlist: false,
      showInExpenseClaims: false,
      enablePaymentsToAccount: false
    },
    validationSchema: accountSchema,
    enableReinitialize: true,
    onSubmit: (values) => onSubmitAccount(values)
  });

  const { values, handleSubmit, setFieldValue } = formik;

  return (
    <DefaultModal
      open={open}
      title="New Account"
      handleOk={handleSubmit}
      handleClose={() => setOpen(false)}
    >
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <FormWrapperInput 
            label="Account Type"
            name="type"
            type="select"
            value={values.accountType}
            isGroupOption={true}
            options={optionAccountTypes}
            onChange={(e) => setFieldValue("type", e)}
          />
          <FormWrapperInput 
            label="Code"
            name="code"
            type="input"
            value={values.code}
            onChange={(e) => setFieldValue("code", e.target.value)}
          />
          <FormWrapperInput 
            label="Name"
            name="name"
            type="input"
            value={values.name}
            onChange={(e) => setFieldValue("name", e.target.value)}
          />
          <FormWrapperInput 
            label="Description"
            name="description"
            type="input"
            value={values.description}
            onChange={(e) => setFieldValue("description", e.target.value)}
          />
          <FormWrapperInput 
            label="Tax"
            name="taxType"
            type="select"
            value={values.taxType}
            options={taxRates.map(taxRate => (
              {
                label: `${taxRate.name} (${taxRate.displayTaxRate}%)`,
                value: taxRate.taxType
              }
            ))}
            onChange={(e) => setFieldValue("taxType", e)}
          />

          <VerticalCheckbox className="mt-2">
            <Checkbox 
              defaultChecked={false} 
              onChange={e => setFieldValue("addToWatchlist", e.target.checked)}
            >
              Show on Dashboard Watchlist
            </Checkbox>
            <Checkbox 
              defaultChecked={false}
              onChange={e => setFieldValue("showInExpenseClaims", e.target.checked)}
            >
              Show in Expense Claims
            </Checkbox>
            <Checkbox 
              defaultChecked={false}
              onChange={e => setFieldValue("enablePaymentsToAccount", e.target.checked)}
            >
              Enable payments to this account
            </Checkbox>
          </VerticalCheckbox>
        </Form>
      </FormikProvider>
    </DefaultModal>
  )
};

ModalNewAccount.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  refetchAccount: PropTypes.func,
};

ModalNewAccount.defaultProps = {
  open: false,
  setOpen: () => {},
  refetchAccount: () => {},
};

export default ModalNewAccount;
