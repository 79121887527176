import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import styled from "styled-components";
import { useFormik, FormikProvider } from 'formik';
import { Checkbox, Form, Input, Select } from 'antd';

import { getVersion } from "utils/common";
import { login, accessOrg } from "services/auth.service";
import Button from "components/button";
import LogoPNG from "assets/images/logo.png";

const { Option } = Select;

const LoginWrapper = styled.div`
  min-height: 100vh;
  width: 100%;
  background: #fafafa;
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    background: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      width: 85px;
    }
  }

  button {
    width: 100%;
  }
`;

const FormWrapper = styled.div`
  width: 500px;
  border-radius: 10px;
  background-color: white;
  padding: 40px 60px;
  margin-top: 50px;

  .title {
    font-weight: bold;
    font-size: 18px;
    text-align: center;
  }

  .small-font {
    span {
      font-size: 13px;
    }
  }
`;

const Login = () => {
  const navigate = useNavigate();

  const [resUser, setResUser] = useState({});
  const [selectedOrg, setSelectedOrg] = useState("");
  const [isSelectOrg, setIsSelectOrg] = useState(false);

  const onLogin = (values) => {
    login(values)
    .then((res) => {
      localStorage.setItem("accessToken", res.data?.token);
      setResUser(res.data);
      setIsSelectOrg(true);
    })
  }

  const onLoginOrg = () => {
    accessOrg()
    .then((res) => {
      localStorage.setItem("currentUser", JSON.stringify({
        ...resUser,
        ...res.data,
      }));
      localStorage.setItem("roles", res.data?.roles ?? []);

      if (res.data?.roles?.includes("admin")) {
        navigate('/admin/users');
      } else {
        navigate("/");
      }
    })
  }

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    enableReinitialize: true,
    onSubmit: (values) => onLogin(values)
  });

  const { handleSubmit, getFieldProps } = formik;

  return (
    <LoginWrapper>
      <div>
        <img src={LogoPNG} alt="logo" />
        <span style={{fontSize: "12px", fontWeight: "600", marginTop: "5px"}}>Version {getVersion()} </span>

        <FormWrapper>
          <FormikProvider value={formik}>
            {
              !isSelectOrg ? (
                <Form
                  name="basic"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  initialValues={{ remember: true }}
                  onFinish={handleSubmit}
                  autoComplete="off"
                >
                  <p className="title">Login to your account</p>

                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your email!',
                      },
                    ]}
                  >
                    <Input 
                      {...getFieldProps('email')} 
                    />
                  </Form.Item>
                  <Form.Item
                    label="Password"
                    name="password"
                    className="mb-0"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your password!',
                      },
                    ]}
                  >
                    <Input 
                      {...getFieldProps('password')} 
                      type="password"
                    />
                  </Form.Item>
                  <Form.Item
                    className="small-font"
                    valuePropName="checked"
                  >
                    <Checkbox>Remember me</Checkbox>
                    <span 
                      className="fl-r cs-pointer" 
                      onClick={() => window.open(`${process.env.REACT_APP_URL}/forgetpassword`, "_blank")}
                    >
                      Forget Password ?
                    </span>
                  </Form.Item>
                  <Form.Item className="mb-0">
                    <Button name="Login" htmlType="submit" style={{width: "100%"}} />
                  </Form.Item>
                </Form>
              ) : (
                <React.Fragment>
                  <p className="title">Select Organization</p>

                  <Select
                    placeholder="Organization"
                    onChange={(val) => {
                      localStorage.setItem("orgid", val);
                      setSelectedOrg(val)
                    }}
                    allowClear
                    style={{width: "100%"}}
                  >
                    {
                      resUser?.organizations?.map((org, index) => (
                        <Option key={index} value={org.corpId}>{org.corpName ? org.corpName : ""}</Option>
                      ))
                    }
                  </Select>

                  <Button 
                    className="mt-2" 
                    name="Login" 
                    type="button" 
                    disabled={selectedOrg === ""}
                    onClick={() => onLoginOrg()} 
                  />
                </React.Fragment>
              )
            }
          </FormikProvider>
        </FormWrapper>
      </div>
    </LoginWrapper>
  );
};

export default Login;
