import React from 'react';
import { Navigate } from 'react-router-dom';

import { getCurrentUser } from "utils/axiosConfig";
import UserMain from "components/layout/Main";

const isAuth = () => {
    let auth = getCurrentUser();
    if (auth) {
      auth = JSON.parse(auth);
    }

    return auth?.roles ?? [];
};

const UserProtectedRoute = ({children}) => {
    const inCheck = isAuth();
    if (!inCheck.includes("user") && !inCheck.includes("admin")) {
        return <Navigate to="/login" />
    }

    return (
        <UserMain>
            {children}
        </UserMain>
    )
};



export default UserProtectedRoute;
