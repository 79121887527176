import React, { useState, useEffect, useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery, useMutation, QueryClient } from "react-query";
import { Input, Select } from "antd";
import { EyeOutlined, DeleteOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';

import PageTitleWrapper from "components/pageTitleWrapper";
import DataTable from "components/dataTable";
import { openConfirmation } from "components/confirmationModal";
import Loading from "components/loading";
import { getInvoices, getRepeatInvoices, updateInvoice, updateRepeatingInvoice } from "services/xero.service";
import { formatDMY } from "utils/formatTime";
import { formatStatusXero } from "utils/formatStatus";

const { Option } = Select;

const optionsFilter = [
  { label: "All", value: "ALL" },
  { label: "Draft", value: "DRAFT" },
  { label: "Awaiting Approval", value: "SUBMITTED" },
  { label: "Awaiting Payment", value: "AUTHORISED" },
  { label: "Paid", value: "PAID" },
  { label: "Repeating", value: "REPEATING" },
]

const RecordKeepingInvoice = () => {
  const queryClient = new QueryClient();
  const navigate = useNavigate();

  const [invoices, setInvoices] = useState([]);
  const [repeatInvoices, setRepeatInvoices] = useState([]);
  const [filterCat, setFilterCat] = useState("ALL");
  const [finishLoad, setFinishLoad] = useState(false);
  const searchValue = useRef();
  const searchButton = useRef();

  const { isSuccess: isSucessInvoice, data: resInvoice, refetch: refetchInvoice } = useQuery(
    ["invoices", { status: filterCat }], 
    getInvoices, 
    { enabled: filterCat !== "REPEATING" }
  );
  const { isSuccess: isSucessRepeat, data: resRepeat, refetch: refetchRepeat } = useQuery(
    ["repeat-invoices", { status: filterCat }], 
    getRepeatInvoices, 
    { enabled: filterCat === "REPEATING" }
  );

  useEffect(() => {
    setInvoices(resInvoice ? resInvoice : []);
    setFinishLoad(true);

    return () => {
      queryClient.removeQueries("invoices");
    };
  }, [isSucessInvoice, resInvoice]);

  useEffect(() => {
    setRepeatInvoices(resRepeat ? resRepeat : []);
    setFinishLoad(true);

    return () => {
      queryClient.removeQueries("repeat-invoices");
    };
  }, [isSucessRepeat, resRepeat]);

  const columnInvoices = [
    {
      title: "Number",
      dataIndex: "invoiceNumber",
      key: "invoiceNumber",
      fixed: "left",
      filterDropdown: ({ setSelectedKeys, confirm }) => (
        <React.Fragment>
          <Input 
            ref={searchValue}
            name="searchInvoiceNo"
          />
          <button 
            ref={searchButton}
            onClick={() => {
              setSelectedKeys(searchValue?.current?.input?.value ? [searchValue.current.input.value] : []);
              confirm();
            }} 
          >
            Search
          </button>
        </React.Fragment>
      ),
      filterDropdownVisible: true,
      onFilter: (value, record) => record.invoiceNumber.toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: "Ref",
      dataIndex: "reference",
      key: "reference",
    },
    {
      title: "To",
      dataIndex: "to",
      key: "to",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (_, record) => formatDMY(record.date)
    },
    {
      title: "Due Date",
      dataIndex: "dueDate",
      key: "dueDate",
      render: (_, record) => formatDMY(record.dueDate)
    },
    {
      title: "Paid",
      dataIndex: "amountPaid",
      key: "amountPaid",
      render: (_, record) => record.amountPaid?.toFixed(2) ?? "0.00"
    },
    {
      title: "Due",
      dataIndex: "amountDue",
      key: "amountDue",
      render: (_, record) => record.amountDue?.toFixed(2) ?? "0.00"
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, record) => formatStatusXero(record.status)
    },
    {
      title: "Sent",
      dataIndex: "sent",
      key: "sent",
      render: (_, record) => record.sent === true ? "Sent" : ""
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "130px",
      render: (_, record) => {
        return (
          <div className="icon-wrapper">
            <EyeOutlined 
              onClick={() => navigate(`/recordkeeping/invoice/${record.invoiceId}`)}
            />
            {
              record.status === "DRAFT" && (
                <DeleteOutlined 
                  onClick={() => openConfirmation({
                    title: "Are you sure want to delete this data ?",
                    onOk: () => handleDelete(record)
                  })}
                />
              )
            }
          </div>
        )
      }
    },
  ];

  const sourceInvoices = useMemo(() => {
    return invoices.map((record, index) => (
      {
        ...record,
        key: index
      }
    ));
  }, [invoices]);

  const columnRepeating = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      fixed: "left"
    },
    {
      title: "Ref",
      dataIndex: "reference",
      key: "reference",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Repeats",
      dataIndex: "repeat",
      key: "repeat",
    },
    {
      title: "Next Invoice Date",
      dataIndex: "nextDate",
      key: "nextDate",
      render: (_, record) => formatDMY(record.nextDate)
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
      render: (_, record) => formatDMY(record.endDate)
    },
    {
      title: "Invoice Will be",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "130px",
      render: (_, record) => {
        return (
          <div className="icon-wrapper">
            <EyeOutlined 
              onClick={() => navigate(`/recordkeeping/repeat-invoice/${record.repeatingInvoiceId}`)}
            />
            <DeleteOutlined 
              onClick={() => openConfirmation({
                title: "Are you sure want to delete this data ?",
                onOk: () => handleDeleteRepeat(record)
              })}
            />
          </div>
        )
      }
    },
  ];

  const sourceRepeats = useMemo(() => {
    return repeatInvoices.map((record, index) => (
      {
        ...record,
        key: index
      }
    ));
  }, [repeatInvoices]);

  const serviceUpdateInvoice = useMutation(values => {
    return updateInvoice({
      data: {
        invoices: [values]
      },
      invoiceId: values.invoiceId
    });
  });

  const handleDelete = async (values) => {
    const formikValues = {...values};
    formikValues.status = "DELETED";

    serviceUpdateInvoice.mutate(formikValues, {
      onSuccess: (res) => {
        if (res) {
          setInvoices(invoices.filter(x => x.invoiceId !== values.invoiceId));
        }
      }
    });
  }

  const serviceUpdateRepeatingInvoice = useMutation(values => {
    return updateRepeatingInvoice({
      data: {
        repeatingInvoices: [values]
      },
      invoiceId: values.repeatingInvoiceId
    })
  });

  const handleDeleteRepeat = async (values) => {
    const formikValues = {...values};
    formikValues.status = "DELETED";
    formikValues.repeatingInvoiceID = values.repeatingInvoiceId;
    formikValues.iD = values.invoiceId;

    serviceUpdateRepeatingInvoice.mutate(formikValues, {
      onSuccess: (res) => {
        setRepeatInvoices(repeatInvoices.filter(x => x.repeatingInvoiceId !== values.repeatingInvoiceId));
      }
    });
  }

  return (
    <div className="body-wrapper">
      <PageTitleWrapper className="title-wrapper">
        <div>
          <h2>Invoice</h2>
        </div>
        <div className="search-input">
          <SearchOutlined />
          <Input
            placeholder="Search..."
            onChange={(e) => {
              if (searchValue?.current) {
                searchValue.current.input.value = e.target.value
                searchButton.current.click();
              }
            }}
            style={{
              width: 200,
            }}
          />
          <Select 
            value={filterCat} 
            className="ml-2"
            style={{width: "200px"}} 
            onChange={(e) => {
              setFilterCat(e);
              if (e !== "REPEATING") {
                refetchInvoice();
              } else {
                refetchRepeat();
              }
            }}
          >
            {
              optionsFilter.map((option, index) => (
                <Option key={index} value={option.value}>{option.label}</Option>
              ))
            }
          </Select>
          <PlusOutlined onClick={() => navigate("/recordkeeping/create-invoice")} />
        </div>
      </PageTitleWrapper>

      {
        !finishLoad && (
          <Loading />
        )
      }

      {
        finishLoad && (
          filterCat !== "REPEATING" ? (
            <DataTable 
              columns={columnInvoices} 
              sources={sourceInvoices} 
              pagination={{
                defaultPageSize: 50
              }}
              scroll={{ y: 800, x: 1500 }}
            />
          ) : (
            <DataTable 
              columns={columnRepeating} 
              sources={sourceRepeats} 
              pagination={{
                defaultPageSize: 50
              }}
              scroll={{ y: 800, x: 1500 }}
            />
          )
        )
      }
    </div>
  );
}


export default RecordKeepingInvoice;
