import { useEffect, useState } from "react";
import { Row, Col, Breadcrumb, Avatar, Dropdown } from "antd";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { AppDrawer } from "@bsilobook/component";

import Button from "components/button";
import ProfileSVG from "assets/images/profile.svg";
import AppIcon from "assets/images/app.svg";
import { SwapOutlined } from '@ant-design/icons';

import { getApps } from "services/user.service";
import { getCurrentUser } from "utils/axiosConfig";

import { menuAdmins, menuUsers } from "./Sidenav";
import { RightBar, StyledMenu, StyledSwtichButton } from "./styled";

function Header() {
  const navigate = useNavigate();
  const [apps, setApps] = useState([]);

  let auth = getCurrentUser();
  if (auth) {
    auth = JSON.parse(auth);
  }
  const roles = localStorage.getItem("roles");
  const token = localStorage.getItem("accessToken");
  const orgId = localStorage.getItem("orgid");

  useEffect(() => {
    getApps()
    .then((res) => {
      const mapRes = res?.data?.map((x) => (
        {
          code: x.code,
          label: x.name,
          link: x.link,
          image: x.image ? x.image : AppIcon
        }
      ))
      setApps(mapRes);
    })
  }, []);

  console.log(apps)
  
  const checkNestedMenu = (menus, str) => {
    let label = "";
    
    menus.map((menu) => {
      if (label === "") {
        if (menu.key === str || str.includes(menu.key)) {
          label = menu.label;
          return false;
        }
  
        if (menu.children) {
          label = checkNestedMenu(menu.children, str);
        }
      }
    });

    return label;
  }

  const getLabelPages = (str) => {
    if (str === "admin")
      return "Admin";

    const label = checkNestedMenu(auth?.roles?.includes("admin") ? menuAdmins : menuUsers, str);
    return label;
  }

  const { pathname } = useLocation();
  const page = pathname.replace("/", "");

  useEffect(() => window.scrollTo(0, 0));

  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("currentUser");
    localStorage.removeItem("roles");
    localStorage.removeItem("orgid");
    navigate("/login");
  }

  const getBreadCrumb = () => {
    let breadcrumb = "Pages";
    const splitpage = page.split("/");
    
    splitpage.map((txt) => {
      const labelPage = getLabelPages(txt);
      if (labelPage !== "") {
        breadcrumb += ` / ${labelPage}`;
      }

      return false;
    })

    return breadcrumb;
  }

  const getActivePage = () => {
    const splitpage = page.split("/");
    if (splitpage.length === 0)
      return "";

    return getLabelPages(splitpage[splitpage.length - 1]);
  }

  const menuAccount = (
    <StyledMenu 
      items={[
        {
          label: <span onClick={() => navigate("/account")}>My Account</span>,
          key: "myAccount"
        },
        {
          label: <span onClick={() => navigate("/account/password")}>Change Password</span>,
          key: "password"
        },
        {
          label: <span onClick={() => handleLogout()}>Logout</span>,
          key: "logout"
        }
      ]}
    />
  )

  return (
    <Row gutter={[24, 0]}>
      <Col span={24} md={6}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <NavLink to="/">{`${getBreadCrumb()}`}</NavLink>
          </Breadcrumb.Item>
        </Breadcrumb>
        <div className="ant-page-header-heading">
          <span
            className="ant-page-header-heading-title"
            style={{ textTransform: "capitalize" }}
          >
            {getActivePage()}
          </span>
        </div>
      </Col>
      <Col span={24} md={18} className="header-control">
        <Dropdown overlay={menuAccount} trigger={["click"]}>
          <Avatar className="cs-pointer" src={auth?.profile === "" ? ProfileSVG : auth?.profile} />
        </Dropdown>


        <RightBar className="mr-2">
          <AppDrawer 
            apps={apps}
            token={token}
            orgId={orgId}
            width="40px"
            height="40px"
            popupRight="20px"
            popupTop="70px"
            bgColor="#ddd"
          />
        </RightBar>

        {
          (roles?.includes("admin")) && (
            <StyledSwtichButton 
              className="btn-switch"
              name={auth?.roles?.includes("admin") ? "Switch User" : "Switch Admin"}
              icon={<SwapOutlined color="white" />}
              onClick={() => {
                let storageAuth = getCurrentUser();
                if (storageAuth) {
                  storageAuth = JSON.parse(storageAuth);
                }
    
                
                if (storageAuth.roles.includes("admin")) {
                  storageAuth.roles = ["user"];
                  localStorage.setItem("currentUser", JSON.stringify(storageAuth));
                  navigate("/");
                } else {
                  storageAuth.roles = ["admin"];
                  localStorage.setItem("currentUser", JSON.stringify(storageAuth));
                  navigate("/admin/users");
                }
              }}
            />
          )
        }
      </Col>
    </Row>
  );
}

export default Header;
