import { API_URL } from "configures/setting";
import countryJson from "countrycitystatejson";
import * as XLSX from "xlsx";

import packageJson from "../../package.json";

export const getSkillLevels = () => {
  return ["Beginner", "Average", "Skilled", "Expert"];
}

export const convertToImageUrl = (url) => {
  return `${API_URL}/assets/${url}`;
}

export const generateRandom = (maxLimit = 100) => {
  let rand = Math.random() * maxLimit;
  rand = Math.floor(rand); // 99

  return rand;
}

export const removeSpace = (str) => {
  if (str) {
    return str.replace(/\s/g, '');
  }

  return str;
}

export const countries = countryJson.getCountries().sort((a, b) => {
  if (a.name < b.name) return -1;
  if (a.name > b.name) return 1;
  return 0;
});

export const getStates = (code) => {
  if (code)
    return countryJson.getStatesByShort(code);

  return [];
}

export const getCities = (code, state) => {
  if (code && state)
    return countryJson.getCities(code, state);

  return [];
}

export const downloadFile = (title, file) => {
  const url = window.URL.createObjectURL(new Blob([file], { type: "application/pdf" }));
  const link = document.createElement("a");
  link.target = "_blank";
  link.download = title;
  link.href = url
  link.click();
}

export const exportXlsx = (values, colWidth, sheetName, downloadName) => {
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.json_to_sheet(values);

  ws["!cols"] = colWidth;

  XLSX.utils.book_append_sheet(wb, ws, sheetName ? sheetName : "Sheet1");
  XLSX.writeFile(wb, `${downloadName ? downloadName : "download"}.xlsx`)
}

export const getVersion = () => {
  return packageJson?.version ? packageJson.version : "1.0.0";
}