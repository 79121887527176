import { Divider, Space, Select } from "antd";
import styled from "styled-components";

export const Wrapper = styled.div`
  label {
    height: 40px;
    width: 100%;
    display: inline-flex;
    align-items: center;
  }

  .ant-radio-wrapper {
    width: inherit !important;
  }

  .ant-radio {
    position: relative;
    top: 0px;
  }
  
  .ant-select {
    width: 100%;
  }

  .group-option .ant-select-selection-item {
    padding-left: 15px !important;
  }

  .ant-select-selector,
  .ant-picker {
    height: 40px !important;
    border-radius: 6px !important;
  }

  .ant-select-selection-search,
  .ant-select-selection-search-input {
    height: 40px !important;
  }
  
  .ant-select-selection-item {
    line-height: 38px !important;
  }

  .ant-picker {
    width: 100%;
  }

  .ant-select-selection-item {
    line-height: 38px;
  }

  .error-text {
    color: #f44336;
    font-size: 13px;
  }

  .error-input {
    border-color: #f44336;
  }
`;

export const StyledDivider = styled(Divider)`
  margin: 8px 0px;
`;

export const SpaceNewItem = styled(Space)`
  cursor: pointer;
  padding: 4px 15px;
  color: #1a73e8;
`;

export const SelectInTable = styled(Select)`
  width: 100%;
  padding: 0px;

  .ant-select-selector {
    padding: 0px !important;
  }
`;