import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Alert } from "antd";
import styled from "styled-components";
import { alertType, onAlert, clear } from "services/alert.service";

const StyledAlert = styled.div`
  position: absolute;
  top: 100px;
  right: 40px;
  min-width: 200px;
  z-index: 10000;
`;

const AlertMessage = ({
  id,
}) => {
  const [alert, setAlert] = useState({});
  const isMountedRef = useRef(null);

  useEffect(() => {
    isMountedRef.current = true;

    const subscription = onAlert(id).subscribe(notif => {
      if (isMountedRef.current) {
        setAlert(notif);
        setTimeout(() => setAlert({}), 3000);
      }
    });

    return () => {
      clear(id);
      subscription.unsubscribe();
      isMountedRef.current = false;
    };
  }, [id]);

  const getVariant = (alert) => {
    if (!alert) return;

    let variant;

    switch (alert.type) {
      case alertType.Success:
        variant = "success";
        break;
      case alertType.Error:
        variant = "error";
        break;
      case alertType.Warning:
        variant = "warning";
        break;
      case alertType.Info:
        variant = "info";
        break;
      default:
        variant = "info";
    }

    return variant;
  }

  return (
    <StyledAlert>
      {
        alert.message && (
          <Alert key="alert" message={alert.message} type={getVariant(alert)} />
        )
      }
    </StyledAlert>
  )
};

AlertMessage.propTypes = {
  id: PropTypes.string,
};

AlertMessage.defaultProps = {
  id: "default-alert",
};

export default AlertMessage;
