import React from "react";
import PropTypes from "prop-types";

import { StyledModal } from "./styled";

const DefaultModal = ({
  open,
  title,
  handleOk,
  handleClose,
  children,
  ...props
}) => {
  return (
    <StyledModal 
      title={title}
      visible={open} 
      onOk={handleOk} 
      onCancel={handleClose}
      {...props}
    >
      {children}
    </StyledModal>
  )
};

DefaultModal.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  handleOk: PropTypes.func,
  handleClose: PropTypes.func,
};

DefaultModal.defaultProps = {
  open: false,
  title: "",
  handleOk: () => {},
  handleClose: () => {},
};

export default DefaultModal;
