import React, { useEffect, useMemo, useState } from "react";
import { DatePicker, Pagination, Input } from "antd";
import moment from "moment";
import styled from "styled-components";

import PageTitleWrapper from "components/pageTitleWrapper";
import DataTable from "components/dataTable";
import Button from "components/button";
import Loading from "components/loading";
import { getAgedReceiveables } from "services/xero.service";
import { removeSpace } from "utils/common";
import { formatDMY } from "utils/formatTime";
import { thousandSepartors } from "utils/formatNumber";

const { RangePicker } = DatePicker;

const PaginationWrapper = styled.div`
  .ant-pagination {
    text-align: center;
    margin-top: 25px;
  }
`;

const FilterWrapper = styled.div`
  .search-input {
    width: 175px;
    margin-left: 15px;
  }
`;

const ReceiveablesSummary = ({
}) => {
  const [payables, setPayables] = useState([]);
  const [fromDate, setFromDate] = useState(moment().subtract(1, "days"));
  const [toDate, setToDate] = useState(moment());
  const [search, setSearch] = useState("");
  const [finishLoad, setFinishLoad] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  useEffect(() => {
    setFinishLoad(false);
    getAgedReceiveables({
      fromDate: formatDMY(fromDate),
      toDate: formatDMY(toDate),
      startIndex: (currentPage - 1) * 10,
      stopIndex: (currentPage * 10) - 1,
      search: search
    })
    .then((res) => {
      if (res) {
        setPayables(res.values);
        setTotalPage(res.total);
        setFinishLoad(true);
      }
    })
  }, [isUpdate]);

  const renderColumn = (record, field) => {
    const getValue = () => {
      if (field === "Due" || field === "Paid" || field === "Total" || field === "Credited")
        return thousandSepartors(record[field], 0);
      else if (field === "DueDate" || field === "Date")
        return record[field] !== "Total" ? formatDMY(record[field]) : record[field];
      else
        return record[field];
    }

    return (
      <span className={record.fwbold ? "fw-bold" : ""}>{getValue()}</span>
    );
  }

  const columns = useMemo(() => {
    const arr = [{
      title: "Contact",
      dataIndex: "Contact",
      key: "Contact",
      width: "30%",
      render: (_, record) => renderColumn(record, "Contact")
    }];
    
    if (payables.length > 0) {
      const selectedPayable = payables.find(x => {
        if (x.reports.length > 0) {
          if (x.reports[0].rows.length > 1) {
            return true;
          }
        }

        return false;
      });

      if (selectedPayable) {
        const columnHeaders = selectedPayable.reports[0].rows[0].cells;

        columnHeaders.map((cell) => {
          const field = removeSpace(cell.value);
          
          if (field !== "") {
            arr.push({
              title: cell.value,
              dataIndex: field,
              key: field,
              render: (_, record) => renderColumn(record, field)
            })
          }
        })
      }
    }

    return arr;
  }, [payables]);

  const sources = useMemo(() => {
    const arr = [];
    if (columns.length > 0) {
      for (let i = 0; i <= payables.length - 1; i++) {
        if (payables[i].reports.length > 0) {
          const report = payables[i].reports[0];
          const childrenRows = [];
        
          // add children values
          if (report.rows.length > 1) {
            report.rows[1].rows?.map((childRow, childRowIdx) => {
              const rowValue = {};
              const cells = childRow.cells;
              cells.map((cell, columnIdx) => {
                const field = report.rows[0].cells[columnIdx].value;
                if (field !== "") {
                  const findIndex = columns.findIndex(x => x.key === removeSpace(field));
                  if (findIndex > 0) {
                    rowValue[columns[findIndex]?.key] = cell.value;
                  }
                }
              });
    
              childrenRows.push({
                key: `${i}-${childRowIdx}`,
                ...rowValue
              })
            });

            // add parent values
            arr.push({
              key: i,
              Contact: payables[i].name,
              fwbold: true,
              children: childrenRows,
            })
          }
  
        }
      }
    }

    return arr;
  }, [payables, columns]);

  return (
    <div className="body-wrapper">
      <PageTitleWrapper className="title-wrapper">
        <div>
          <h2>Aged Receiveables Summary</h2>
        </div>
        <FilterWrapper>
          <RangePicker 
            value={[fromDate, toDate]}
            onChange={(_, dateString) => {
              setFromDate(moment(dateString[0]));
              setToDate(moment(dateString[1]));
            }}
          />
          <Input 
            className="search-input"
            type="text"
            placeholder="Search Contact"
            value={search}
            onChange={e => setSearch(e.target.value)}
          />
          <Button name="Update" className="ml-2" onClick={() => setIsUpdate(!isUpdate)} />
        </FilterWrapper>
      </PageTitleWrapper>

      {
        !finishLoad && (
          <Loading />
        )
      }

      {
        finishLoad && (
          <React.Fragment>
            <DataTable 
              columns={columns} 
              sources={sources} 
              pagination={false}
              defaultExpandAllRows={true}
              scroll={{ x: 1000 }}
            />
            <PaginationWrapper>
              <Pagination 
                defaultCurrent={currentPage} 
                total={totalPage} 
                onChange={(page) => {
                  setCurrentPage(page);
                  setIsUpdate(!isUpdate);
                }} 
              />
            </PaginationWrapper>
          </React.Fragment>
        )
      }
    </div>
  );
}


export default ReceiveablesSummary;
