import React, { useMemo, useState, useEffect } from "react";
import { DatePicker } from "antd";
import moment from "moment";

import PageTitleWrapper from "components/pageTitleWrapper";
import DataTable from "components/dataTable";
import Button from "components/button";
import Loading from "components/loading";
import { getCashflow } from "services/xero.service";
import { thousandSepartors } from "utils/formatNumber";

const { RangePicker } = DatePicker;

const cashflowBalanceFields = [
  {
    field: "openingCashBalance",
    name: "Cash and cash equivalents at beginning of period",
    fwbold: false,
  },
  {
    field: "netCashMovement",
    name: "Net change in cash for period",
    fwbold: false,
  },
  {
    field: "closingCashBalance",
    name: "Cash and cash equivalents at end of period",
    fwbold: true,
  }
]

const Cashflow = ({
}) => {
  const [cashflow, setCashflow] = useState(null);
  const [fromDate, setFromDate] = useState(moment());
  const [toDate, setToDate] = useState(moment())
  const [finishLoad, setFinishLoad] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);

  useEffect(() => {
    getCashflow({ fromDate, toDate })
    .then((res) => {
      setCashflow(res);
      setFinishLoad(true);
    });
  }, [isUpdate]);

  const renderAmountValue = (value) => {
    if (value < 0) {
      return `(${thousandSepartors(value * -1)})`;
    }

    return thousandSepartors(value);
  }

  const renderColumn = (record, field) => {
    if (field !== "amount") {
      return (
        <span className={record.fwbold ? "fw-bold" : ""}>{record[field]}</span>
      );
    }

    return (
      <span className={record.fwbold ? "fw-bold" : ""}>{renderAmountValue(record[field])}</span>
    )
  }

  const columnCashflow = [
    {
      title: "",
      dataIndex: "name",
      key: "name",
      render: (_, record) => renderColumn(record, "name")
    },
    {
      title: "Total",
      dataIndex: "amount",
      key: "amount",
      width: "15%",
      render: (_, record) => renderColumn(record, "amount")
    },
  ];

  const sourceCashflow = useMemo(() => {
    let arr = [];

    if (cashflow) {
      arr = cashflow.cashflowActivities?.map(x => (
        {
          key: x.name,
          name: x.name,
          amount: x.amount,
          fwbold: true,
          children: x.children?.map(c => (
            {
              key: c.name,
              name: c.name,
              amount: c.amount
            }
          ))
        }
      ));

      arr.push({
        key: "netCashflow",
        name: "Net Cash Flows",
        amount: cashflow?.netCashflow,
        fwbold: true,
      });
  
      arr.push({
        key: "cashEquivalents",
        name: "Cash and Cash Equivalents",
        amount: "",
        fwbold: true,
        children: cashflowBalanceFields.map(x => (
          {
            key: x.field,
            name: x.name,
            amount: cashflow?.cashBalance[x.field],
            fwbold: x.fwbold
          }
        ))
      });
    }

    return arr;
  }, [cashflow]);

  return (
    <div className="body-wrapper">
      <PageTitleWrapper className="title-wrapper">
        <div>
          <h2>Statement of Cash Flow</h2>
        </div>

        <div>
          {/* <RangePicker
            value={[fromDate, toDate]}
            onChange={(_, dateString) => {
              setFromDate(moment(dateString[0]));
              setToDate(moment(dateString[1]));
            }}
          />
          <Button 
            name="Update"
            className="ml-2"
            onClick={() => setIsUpdate(!isUpdate)}
          /> */}
        </div>
      </PageTitleWrapper>

      {
        !finishLoad && (
          <Loading />
        )
      }

      {
        finishLoad && (
          <DataTable 
            columns={columnCashflow} 
            sources={sourceCashflow} 
            bgColor="white"
            color="inherit"
            pagination={false}
            defaultExpandAllRows={true}
            scroll={{ y: 680 }}
          />
        )
      }
    </div>
  );
}


export default Cashflow;
