import React from 'react';
import { Navigate } from 'react-router-dom';

import { getCurrentUser } from "utils/axiosConfig";
import AdminMain from "components/layout/Main";

const isAuth = () => {
    let auth = getCurrentUser();
    if (auth) {
      auth = JSON.parse(auth);
    }

    return auth?.roles ?? [];
};

const AdminProtectedRoute = ({children}) => {
    const inCheck = isAuth();
    if (!inCheck.includes("admin")) {
        return <Navigate to="/login" />
    }

    return (
        <AdminMain>
            {children}
        </AdminMain>
    )
};



export default AdminProtectedRoute;
