import React, { useEffect, useState, useMemo } from "react";
import { useQuery, QueryClient } from "react-query";
import { DatePicker, Select, Input } from "antd";
import styled from "styled-components";
import moment from "moment";

import { getProfitLoss } from "services/xero.service";
import PageTitleWrapper from "components/pageTitleWrapper";
import DataTable from "components/dataTable";
import Button from "components/button";
import Loading from "components/loading";
import { removeSpace } from "utils/common";
import { thousandSepartors } from "utils/formatNumber";

const { RangePicker } = DatePicker;
const { Option } = Select;

const StyledPeriod = styled.div`
  display: inline-block;

  > input {
    width: 60px;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    text-align: center;
  }

  .ant-select-selector {
    width: 150px !important;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }
`;

const ProfitLoss = () => {
  const queryClient = new QueryClient();

  const [fromDate, setFromDate] = useState(moment().startOf("month"));
  const [toDate, setToDate] = useState(moment().endOf("month"));
  const [period, setPeriod] = useState(1);
  const [timeFrame, setTimeFrame] = useState("MONTH");
  const [paymentOnly, setPaymentOnly] = useState(false);
  const [profitLoss, setProfitLoss] = useState(null);
  const [finishLoad, setFinishLoad] = useState(false);

  const { isSuccess: isSuccessPL, data: resPL, refetch: refetchPL } = useQuery(
    [
      "profitloss", 
      { 
        fromDate: moment(fromDate).format("YYYY-MM-DD"),
        toDate: moment(toDate).format("YYYY-MM-DD"),
        period: period,
        timeFrame: timeFrame,
        paymentOnly: paymentOnly,
      },
    ], 
    getProfitLoss, 
    { enabled: !finishLoad }
  );

  useEffect(() => {
    if (isSuccessPL) {
      if (resPL) {
        if (resPL?.reports.length > 0) {
          const report = resPL.reports[0];
          setProfitLoss(report.rows);
        }

        setFinishLoad(true);
      }
    }

    return () => {
      queryClient.removeQueries("profitloss");
    };
  }, [isSuccessPL, resPL]);

  const renderColumn = (record, field) => {
    const getValue = () => {
      if (field === "Subject")
        return record[field];
      else
        return thousandSepartors(record[field], 0);
    }

    return (
      <span className={record.fwbold ? "fw-bold" : ""}>{getValue()}</span>
    );
  }

  const columnProfitLoss = useMemo(() => {
    if (profitLoss?.length > 0) {
      return profitLoss[0].cells.map((cell, cellIdx) => {
        const field = cell.value !== "" ? removeSpace(cell.value) : "Subject";

        return {
          title: cell.value,
          dataIndex: field,
          key: field,
          width: cellIdx === 0 ? 250 : 150,
          fixed: cellIdx === 0 ? "left" : "",
          render: (_, record) => renderColumn(record, field)
        }
      })
    } 

    return [];
  }, [profitLoss]);

  const sourceProfitLoss = useMemo(() => {
    const arr = [];
    if (columnProfitLoss.length > 0) {
      for (let i = 1; i <= profitLoss.length - 1; i++) {
        const childrenRows = [];

        // add children values
        profitLoss[i].rows.map((childRow, childRowIdx) => {
          const rowValue = {};
          columnProfitLoss.map((column, columnIdx) => {
            rowValue[column.key] = childRow.cells[columnIdx].value;
          });

          childrenRows.push({
            key: `${i}-${childRowIdx}`,
            ...rowValue
          })
        });

        // add parent values
        if (profitLoss[i].title === "" && childrenRows.length === 1) {
          // empty parent subject
          arr.push({
            ...childrenRows[0],
            fwbold: true,
          })
        } else {
          // got parent subject
          arr.push({
            key: i,
            Subject: profitLoss[i].title,
            fwbold: true,
            children: childrenRows,
          })
        }
      }

    }

    return arr;
  }, [profitLoss, columnProfitLoss]);

  return (
    <div className="body-wrapper">
      <PageTitleWrapper className="title-wrapper">
        <div>
          <h2>Profit and Loss</h2>
        </div>

        <div>
          <RangePicker
            value={[fromDate, toDate]}
            onChange={(_, dateString) => {
              setFromDate(moment(dateString[0]));
              setToDate(moment(dateString[1]));
            }}
          />

          <StyledPeriod className="ml-2">
            <Input 
              type="number"
              value={period}
              onChange={e => setPeriod(e.target.value)}
            />
            <Select 
              value={timeFrame} 
              onChange={(e) => setTimeFrame(e)}
            >
              <Option key={"MONTH"} value={"MONTH"}>Month</Option>
              <Option key={"QUARTER"} value={"QUARTER"}>Quarter</Option>
              <Option key={"YEAR"} value={"YEAR"}>Year</Option>
            </Select>
          </StyledPeriod>

          <Select 
            value={paymentOnly} 
            className="ml-2"
            style={{width: "150px"}} 
            onChange={(e) => setPaymentOnly(e)}
          >
            <Option key={"Accrual"} value={false}>Accrual</Option>
            <Option key={"Cash"} value={true}>Cash</Option>
          </Select>

          <Button 
            name="Update"
            className="ml-2"
            onClick={() => {
              setFinishLoad(false);
              refetchPL()
            }}
          />
        </div>
      </PageTitleWrapper>

      {
        !finishLoad && (
          <Loading />
        )
      }
      
      {
        finishLoad && (
          <DataTable 
            columns={columnProfitLoss} 
            sources={sourceProfitLoss} 
            pagination={false}
            defaultExpandAllRows={true}
            scroll={{ x: 1000, y: 700 }}
          />
        )
      }
    </div>
  );
}


export default ProfitLoss;
