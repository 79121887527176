import React from "react";
import { Spin } from 'antd';

import styled from "styled-components";

const StyledWrapper = styled.div`
  text-align: center;
  margin-top: 25px;
  margin-bottom: 25px;

  .ant-spin-dot-item {
    background-color: #219653;
  }
`;

const Loading = ({
  ...props
}) => {
  return (
    <StyledWrapper {...props}>
      <Spin size="large" />
    </StyledWrapper>
  )
};

export default Loading;
