import styled from "styled-components";

export const Wrapper = styled.div`
  > div {
    display: inline-block;
  }

  > div:last-child {
    float: right;
  }
`;