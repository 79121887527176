import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation } from "react-query";
import { isEmpty } from "lodash";

import { verifyToken } from "services/auth.service";

const JumpAuthorize = () => {
  const { search } = useLocation();
  const navigate = useNavigate();

  const [response, setResponse] = useState({});

  const searchParams = useMemo(
    () => new URLSearchParams(search),
    [search]
  );

  const bearerToken = searchParams.get("token");
  const splitOrg = search?.toString().split("organizationId=");
  const organizationId = splitOrg.length > 0 ? splitOrg[splitOrg.length - 1] : "";

  const serviceVerifyToken = useMutation(_ => {
    return verifyToken({
      data: {
        bearerToken: bearerToken,
        orgId: organizationId
      },
    })
  });

  useEffect(() => {
    serviceVerifyToken.mutate({}, {
      onSettled: (res) => {
        if (res?.data?.isSuccess) {
          setResponse(res?.data);
        } else {
          setResponse({ isSuccess: false });
        }
      }
    });
  }, [])

  useEffect(() => {
    if (!isEmpty(response) && response?.isSuccess !== undefined) {
      if (response?.isSuccess === true) {
        localStorage.setItem("accessToken", response?.token);
        localStorage.setItem("orgid", response?.orgId);
        localStorage.setItem("currentUser", JSON.stringify(response?.user));
        localStorage.setItem("roles", response?.user?.role);

        if (response?.user?.roles?.includes("admin")) {
          navigate("/admin/users");
        } else {
          navigate("/");
        }
      } else {
        navigate("/");
      }
    }
  }, [response])

  return (
    <div></div>
  );
};

export default JumpAuthorize;
