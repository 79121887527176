import React from "react";
import PropTypes from "prop-types";
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider, FieldArray } from 'formik';
import { Radio } from "antd";
import { PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { useMutation } from "react-query";

import DefaultModal from "components/defaultModal";
import FormWrapperInput from "components/formWrapperInput";
import { createTaxRate } from "services/xero.service";

import { InlineBox, StyledDivider } from "./styled";

const ModalNewTaxRate = ({
  open,
  setOpen,
  refetchTax,
}) => {
  let accountSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
  });

  const submitTaxRate = useMutation(values => {
    return createTaxRate({
      data: {
        taxRates: [values]
      }
    })
  })

  const onSubmitTaxRate = (values) => {
    submitTaxRate.mutate(values, {
      onSuccess: (res) => {
        refetchTax();
        setOpen(false);
      }
    });
  }

  const formik = useFormik({
    initialValues: {
      name: "",
      taxComponents: [],
    },
    validationSchema: accountSchema,
    enableReinitialize: true,
    onSubmit: (values) => onSubmitTaxRate(values)
  });

  const { values, handleSubmit, setFieldValue, getFieldProps } = formik;

  const addComponent = (arrayHelpers, getFieldProps) => {
    arrayHelpers.insert(
      getFieldProps("taxComponents")?.value?.length, 
      { 
        name: "",
        rate: 0,
        isCompound: false,
        isNonRecoverable: false
      }
    );
  }

  const getTotalTaxRate = () => {
    return values.taxComponents.reduce((acc, obj) => { return Number(acc) + Number(obj.rate) }, 0);
  }

  return (
    <DefaultModal
      open={open}
      title="New Tax Rate"
      handleOk={handleSubmit}
      handleClose={() => setOpen(false)}
    >
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <FormWrapperInput 
            label="Tax Rate Display Name"
            name="name"
            type="input"
            value={values.name}
            onChange={(e) => setFieldValue("name", e.target.value)}
          />
          <FieldArray 
            name="taxComponents"
            render={arrayHelpers => (
              <div>
                <div 
                  onClick={() => addComponent(arrayHelpers, getFieldProps)}
                  className="fw-bold cs-pointer mt-2" 
                  style={{width: "135px"}}
                >
                  <PlusCircleOutlined />
                  <span className="ml-1">Add Component</span>
                </div>
                {
                  values.taxComponents?.map((component, index) => (
                    <React.Fragment key={index}>
                      <div>
                        <InlineBox width="200px">
                          <FormWrapperInput 
                            label={`Component ${index + 1}`}
                            name={`taxComponents.${index}.name`}
                            type="input"
                            value={component.name}
                            onChange={(e) => setFieldValue(`taxComponents.${index}.name`, e.target.value)}
                          />
                        </InlineBox>
                        <InlineBox width="120px">
                          <FormWrapperInput 
                            label="Rate (%)"
                            name={`taxComponents.${index}.rate`}
                            type="number"
                            value={component.rate}
                            onChange={(e) => setFieldValue(`taxComponents.${index}.rate`, e.target.value)}
                          />
                        </InlineBox>
                        <MinusCircleOutlined 
                          className="cs-pointer"
                          onClick={() => arrayHelpers.remove(index)}
                        />
                      </div>
                      <div className="mt-1 mb-1">
                        <Radio onChange={() => setFieldValue(`taxComponents.${index}.isNonRecoverable`, component.isNonRecoverable)}>
                          Non-recoverable
                        </Radio>
                        <Radio onChange={() => setFieldValue(`taxComponents.${index}.isCompound`, component.isCompound)}>
                          Compound (apply to taxed subtotal)
                        </Radio>
                      </div>

                      <StyledDivider />
                    </React.Fragment>
                  ))
                }
              </div>
            )}
          />

          <p className="fw-bold mt-2">
            Total Tax Rate: {getTotalTaxRate()}%
          </p>
        </Form>
      </FormikProvider>
    </DefaultModal>
  )
};

ModalNewTaxRate.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  refetchTax: PropTypes.func,
};

ModalNewTaxRate.defaultProps = {
  open: false,
  setOpen: () => {},
  refetchTax: () => {},
};

export default ModalNewTaxRate;
