import React from "react";
import PropTypes from "prop-types";
import { Col, Radio, Checkbox } from "antd";

import FormWrapperInput from "components/formWrapperInput";
import { formatInputDate } from "utils/formatTime";
import { labelStatusRepeatUnit, labelStatusPaymentTerm } from "utils/formatStatus";

import { InlineBox, VerticalCheckbox } from "./styled";

const repeatOptions = Object.keys(labelStatusRepeatUnit).map((key) => (
  {
    label: labelStatusRepeatUnit[key],
    value: key
  }
));

const paymentTerms = Object.keys(labelStatusPaymentTerm).map((key) => (
  {
    label: labelStatusPaymentTerm[key],
    value: key
  }
));

const RepeatingInvoiceForm = ({
  values,
  errors,
  touched,
  setFieldValue,
  isDisabled
}) => {
  return (
    <React.Fragment>
      {/* <Col span={24}>
        <InlineBox className="mt-2 mb-1" width="500px">
          <Radio.Group onChange={e => {
            if (e.target.value !== "Sending") {
              setFieldValue("Status", e.target.value);
            } else {
              setFieldValue("Status", "AUTHORISED");
              setFieldValue("approvedForSending", true);
            }
          }}>
            <Radio value={"DRAFT"}>Save as Draft</Radio>
            <Radio value={"AUTHORISED"}>Approve</Radio>
            <Radio value={"Sending"}>Approve for Sending</Radio>
          </Radio.Group>
        </InlineBox>
      </Col> */}
      <Col span={24}>
        <InlineBox width={"120px"}>
          <FormWrapperInput 
            label="Repeat Number"
            name="schedule.period"
            type="number"
            value={values.schedule?.period}
            onChange={(e) => setFieldValue("schedule.period", e.target.value)}
            error={Boolean(touched.schedule?.period && errors.schedule?.period)}
            helpertext={touched.schedule?.period && errors.schedule?.period}
            disabled={isDisabled}
          />
        </InlineBox>
        <InlineBox>
          <FormWrapperInput 
            label="Repeat Type"
            name="schedule.unit"
            type="select"
            value={values.schedule?.unit}
            options={repeatOptions}
            onChange={(e) => setFieldValue("schedule.unit", e)}
            error={Boolean(touched.schedule?.unit && errors.schedule?.unit)}
            helpertext={touched.schedule?.unit && errors.schedule?.unit}
            disabled={isDisabled}
          />
        </InlineBox>
        <InlineBox className="ml-2" width="500px" style={{position: "relative", top: "38px"}}>
          
        </InlineBox>
      </Col>
      <Col span={24}>
        <InlineBox>
          <FormWrapperInput 
            label="Invoice Date"
            name="schedule.startDate"
            type="date"
            value={values.schedule?.startDate ? formatInputDate(values.schedule?.startDate) : ""}
            onChange={(_, dateString) => setFieldValue("schedule.startDate", dateString)}
            error={Boolean(touched.schedule?.startDate && errors.schedule?.startDate)}
            helpertext={touched.schedule?.startDate && errors.schedule?.startDate}
            disabled={isDisabled}
          />
        </InlineBox>
        <InlineBox width={"120px"}>
          <FormWrapperInput 
            label="Due"
            name="schedule.dueDate"
            type="number"
            value={values.schedule?.dueDate}
            onChange={(e) => setFieldValue("schedule.dueDate", e.target.value)}
            error={Boolean(touched.schedule?.dueDate && errors.schedule?.dueDate)}
            helpertext={touched.schedule?.dueDate && errors.schedule?.dueDate}
            disabled={isDisabled}
          />
        </InlineBox>
        <InlineBox>
          <FormWrapperInput 
            label="Due Type"
            name="schedule.dueDateType"
            type="select"
            value={values.schedule?.dueDateType}
            options={paymentTerms}
            onChange={(e) => setFieldValue("schedule.dueDateType", e)}
            error={Boolean(touched.schedule?.dueDateType && errors.schedule?.dueDateType)}
            helpertext={touched.schedule?.dueDateType && errors.schedule?.dueDateType}
            disabled={isDisabled}
          />
        </InlineBox>
        <InlineBox>
          <FormWrapperInput 
            label="End Date (Optional)"
            name="schedule.endDate"
            type="date"
            value={values.schedule?.endDate ? formatInputDate(values.schedule?.endDate) : ""}
            onChange={(_, dateString) => setFieldValue("schedule.endDate", dateString)}
            disabled={isDisabled}
          />
        </InlineBox>
      </Col>
      <Col span={24}>
        <p className="mt-2">Status :</p>
        <Radio.Group value={values.approvedForSending ? "" : values.status} onChange={e => {
          if (e.target.value !== "") {
            setFieldValue("status", e.target.value);
            setFieldValue("approvedForSending", false);
          } else {
            setFieldValue("status", "AUTHORISED");
            setFieldValue("approvedForSending", true);
          }
        }}>
          <Radio value={"DRAFT"}>Save as Draft</Radio>
          <Radio value={"AUTHORISED"}>Approve</Radio>
          <Radio value={""}>Approve for Sending</Radio>
        </Radio.Group>
      </Col>

      {
        values.approvedForSending && (
          <Col span={24}>
            <InlineBox>
              <FormWrapperInput 
                label="Email"
                name="emailAddress"
                type="input"
                value={values.emailAddress}
                onChange={e => setFieldValue("emailAddress", e.target.value)}
              />
            </InlineBox>

            <VerticalCheckbox className="mt-2">
              <Checkbox 
                defaultChecked={values.hasAttachments} 
                onChange={e => setFieldValue("hasAttachments", e.target.checked)}
              >
                Include files as attachments
              </Checkbox>
              <Checkbox 
                defaultChecked={values.includePDF} 
                onChange={e => setFieldValue("includePDF", e.target.checked)}
              >
                Include PDF attachments
              </Checkbox>
              <Checkbox 
                defaultChecked={values.markAsSent} 
                onChange={e => setFieldValue("markAsSent", e.target.checked)}
              >
                Mark as sent
              </Checkbox>
              <Checkbox 
                defaultChecked={values.sendCopy} 
                onChange={e => setFieldValue("sendCopy", e.target.checked)}
              >
                Send me a copy
              </Checkbox>
            </VerticalCheckbox>
          </Col>
        )
      }
    </React.Fragment>
  )
};

RepeatingInvoiceForm.propTypes = {
  values: PropTypes.object,
  errors: PropTypes.object,
  touched: PropTypes.object,
  setFieldValue: PropTypes.func,
  isDisabled: PropTypes.bool,
};

RepeatingInvoiceForm.defaultProps = {
  values: {},
  errors: {},
  touched: {},
  setFieldValue: () => {},
  isDisabled: false
};


export default RepeatingInvoiceForm;
