import Router from "routes";

import AlertMessage from "components/alertMessage";

import "antd/dist/antd.min.css";
import "assets/styles/main.css";
import "assets/styles/responsive.css";
import "index.css";

function App() {
  return (
    <div className="App">
      <Router />
      <AlertMessage />
    </div>
  );
}

export default App;
