import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 1000px;
  margin: auto;
  height: 220px;
  padding-left: 35px;
  border-radius: 10px;
  background-color: #F8F9FA;

  > div {
    display: flex;
    height: 100%;
    align-items: center;
  }

  .detail-wrapper {
    width: 750px;
  }

  .balance-wrapper {
    width: 300px;
    height: 100%;
    display: grid;
    align-items: center;
    text-align: center;
    background-color: #219653;
    border-top-left-radius: 120px;
    border-bottom-left-radius: 120px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;

    > div {
      width: 100%;

      h3 {
        color: white;
        font-size: 28px;
        margin: 0px;
      }

      span {
        font-weight: 600;
        color: #ffffff9e;
      }
    }
  }

  h3 {
    font-size: 20px;
  }
`;