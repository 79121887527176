import React from "react";
import PropTypes from "prop-types";
import { Col } from "antd";

import FormWrapperInput from "components/formWrapperInput";
import { formatInputDate } from "utils/formatTime";

import { InlineBox } from "./styled";

const NormalInvoiceForm = ({
  values,
  errors,
  touched,
  setFieldValue,
  isDisabled,
}) => {
  return (
    <Col span={24}>
      <InlineBox>
        <FormWrapperInput 
          label="Date"
          name="date"
          type="date"
          value={values.date ? formatInputDate(values.date) : ""}
          onChange={(_, dateString) => setFieldValue("date", dateString)}
          error={Boolean(touched.date && errors.date)}
          helpertext={touched.date && errors.date}
          disabled={isDisabled}
        />
      </InlineBox>
      <InlineBox>
        <FormWrapperInput 
          label="Due Date"
          name="dueDate"
          type="date"
          value={values.dueDate ? formatInputDate(values.dueDate) : ""}
          onChange={(_, dateString) => setFieldValue("dueDate", dateString)}
          disabled={isDisabled}
        />
      </InlineBox>
    </Col>
  )
};

NormalInvoiceForm.propTypes = {
  values: PropTypes.object,
  errors: PropTypes.object,
  touched: PropTypes.object,
  setFieldValue: PropTypes.func,
  isDisabled: PropTypes.bool,
};

NormalInvoiceForm.defaultProps = {
  values: {},
  errors: {},
  touched: {},
  setFieldValue: () => {},
  isDisabled: false
};

export default NormalInvoiceForm;
