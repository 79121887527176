import { Modal } from "antd";
import { ExclamationCircleOutlined } from '@ant-design/icons';

export const openConfirmation = ({
  title, 
  content, 
  onOk, 
  onCancel
}) => {
  Modal.confirm({
    title: title,
    icon: <ExclamationCircleOutlined />,
    content: content,
    okText: 'Confirm',
    cancelText: 'Cancel',
    onOk: onOk,
    onCancel: onCancel
  });
}