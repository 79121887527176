import React, { useMemo, useState, useEffect } from "react";
import { Select, DatePicker } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import moment from "moment";

import PageTitleWrapper from "components/pageTitleWrapper";
import BankCard from "components/bankCard";
import DataTable from "components/dataTable";
import Button from "components/button";
import Loading from "components/loading";
import { getAccounts, getBankReconciliation } from "services/xero.service";
import { exportXlsx } from "utils/common";
import { formatDMY } from "utils/formatTime";
import { thousandSepartors } from "utils/formatNumber";

const { Option} = Select;

const BankReconciliation = () => {
  const [summary, setSummary] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [accountId, setAccountId] = useState("");
  const [selectedDate, setSelectedDate] = useState(moment());
  const [balanceAmount, setBalanceAmount] = useState(0);
  const [statementBalance, setStatementBalance] = useState(0);
  const [finishLoadAcc, setFinishLoadAcc] = useState(false);
  const [finishLoad, setFinishLoad] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);

  useEffect(() => {
    getAccounts({type: "BANK"})
    .then((res) => {
      setAccounts(res.accounts);
      setAccountId(res.accounts.length > 0 ? res.accounts[0].accountID : "");
      setFinishLoadAcc(true);
    })
  }, []);

  useEffect(() => {
    if (finishLoadAcc) {
      getBankReconciliation({ accountId: accountId })
      .then((res) => {
        setSummary({
          totalOutstandingPayment: res.totalOutstandingPayment,
          plusOutstandingPayments: res.plusOutstandingPayments,
          statementLines: res.statementLines,
          totalStatementLines: res.totalStatementLines,
        });
        setBalanceAmount(res.balanceAmount);
        setStatementBalance(res.statementBalance);
        setFinishLoad(true);
      });
    }
  }, [isUpdate, finishLoadAcc]);

  const renderAmountValue = (value) => {
    if (value < 0) {
      return `(${thousandSepartors(value * -1)})`;
    }

    return thousandSepartors(value);
  }

  const renderColumn = (record, field) => {
    if (field !== "amount") {
      return (
        <span className={record.fwbold ? "fw-bold" : ""}>{record[field]}</span>
      );
    }

    return (
      <span className={record.fwbold ? "fw-bold" : ""}>{renderAmountValue(record[field])}</span>
    )
  }

  const columnRecords = [
    {
      title: "",
      dataIndex: "name",
      key: "name",
      width: "300px",
      render: (_, record) => renderColumn(record, "name")
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width: "10%",
      render: (_, record) => formatDMY(record.date)
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (_, record) => renderColumn(record, "description")
    },
    {
      title: "Reference",
      dataIndex: "reference",
      key: "reference",
      render: (_, record) => renderColumn(record, "reference")
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      width: "10%",
      render: (_, record) => renderColumn(record, "amount")
    },
  ];

  const getChildrenValues = (values, parentIndex) => {
    const childrens = [];
    values?.map((record, index) => {
      childrens.push({
        key: `${parentIndex}-${index}`,
        name: "",
        date: record.date,
        description: record.description,
        reference: record.reference,
        amount: record.amount
      });
    })

    return childrens;
  }

  const sourceRecords = useMemo(() => {
    if (summary) {
      return [
        {
          key: "TotalOutstandingPayment",
          name: "Total Outstanding Payments",
          description: "",
          reference: "",
          date: "",
          amount: summary.totalOutstandingPayment,
          fwbold: true,
          children: getChildrenValues(summary.plusOutstandingPayments, "1")
        },
        {
          key: "TotalStatementLines",
          name: "Total Un-Reconciled Statement Lines",
          description: "",
          reference: "",
          date: "",
          amount: summary.totalStatementLines,
          fwbold: true,
          children: getChildrenValues(summary.statementLines, "2")
        }
      ];
    }

    return [];
  }, [summary]);

  const getAccountDetail = () => {
    const selectedAccount = accounts.find(x => x.accountID === accountId);  

    return {
      name: selectedAccount?.name ?? "",
      accountNo: selectedAccount?.bankAccountNumber ?? "",
      balance: balanceAmount,
      statementBalance: statementBalance,
    }
  }

  const onExport = () => {
    const accountDetail = getAccountDetail();
    const arr = [];
    
    arr.push({});
    arr.push({
      Date: formatDMY(selectedDate),
      Description: "Balance in Xero",
      Reference: "",
      Amount: thousandSepartors(accountDetail.balance),
    });
    
    sourceRecords.map((x, index) => {
      arr.push({});
      arr.push({
        Date: x.name,
        Description: "",
        Reference: "",
        Amount: thousandSepartors(x.amount),
      });

      x.children.map((child, childIdx) => {
        arr.push({
          Date: formatDMY(child.date),
          Description: child.description,
          Reference: child.reference,
          Amount: thousandSepartors(child.amount),
        });

        return false;
      });

      return false;
    });

    arr.push({});
    arr.push({
      Date: formatDMY(selectedDate),
      Description: "Statement Balance",
      Reference: "",
      Amount: thousandSepartors(accountDetail.statementBalance),
    });

    const colWidth = [ {wch: 12}, {wch: 20}, {wch: 20}, {wch: 12} ];
    exportXlsx(arr, colWidth, "Sheet1", `Bank Reconciliation Summary - ${formatDMY(selectedDate)}`);
  } 

  return (
    <div className="body-wrapper">
      <PageTitleWrapper className="title-wrapper">
        <div>
          <h2>Bank Reconciliation Summary</h2>
        </div>
        <div>
          <Select 
            value={accountId} 
            className="mr-2"
            style={{width: "150px"}} 
            onChange={(e) => setAccountId(e)}
          >
            {
              accounts.map((account, index) => (
                <Option key={index} value={account.accountID}>{account.name}</Option>
              ))
            }
          </Select>
          {/* <DatePicker 
            value={selectedDate}
            onChange={(_, dateString) => setSelectedDate(moment(dateString))}
          /> */}
          <Button name="Update" className="ml-2" onClick={() => setIsUpdate(!isUpdate)} />
          <Button 
            name="Export" 
            className="ml-2" 
            icon={<DownloadOutlined />}
            onClick={() => onExport()} 
          />
        </div>
      </PageTitleWrapper>

      {
        !finishLoad && (
          <Loading />
        )
      }

      {
        finishLoad && (
          <React.Fragment>
            <BankCard 
              className="mb-3"
              bank={getAccountDetail()}
              source={{
                name: "Xero"
              }}
            />
            <DataTable 
              columns={columnRecords} 
              sources={sourceRecords} 
              pagination={false}
              defaultExpandAllRows={true}
              scroll={{ y: 680 }}
            />
          </React.Fragment>
        )
      }
    </div>
  );
}


export default BankReconciliation;
