import React, { useMemo, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery, QueryClient } from "react-query";
import { Row, Col } from "antd";

import { getBankTransaction, getItems, getTaxRates, getAccounts } from "services/xero.service";
import PageTitleWrapper from "components/pageTitleWrapper";
import DataTable from "components/dataTable";
import { formatDMY } from "utils/formatTime";

import { StyledWrapper, FooterInvoice, StyledDivider } from "./styled";

const BankTransactionDetail = () => {
  const queryClient = new QueryClient();

  const { id } = useParams();
  const [statePayment, setStatePayment] = useState(null);
  const [items, setItems] = useState([]);
  const [taxRates, setTaxRates] = useState([]);
  const [accounts, setAccounts] = useState({});

  const { isSuccess: isSuccessItems, data: resItem } = useQuery(["items"], getItems);
  const { isSuccess: isSuccessTaxRates, data: resTaxRate } = useQuery(["taxRates"], getTaxRates, { enabled: isSuccessItems });
  const { isSuccess: isSuccessAccount, data: resAccount } = useQuery(["accounts"], getAccounts, { enabled: isSuccessTaxRates });
  const { isSuccess, data } = useQuery(["bankTransactionDetail", { bankTransactionId: id }], getBankTransaction, { enabled: isSuccessAccount });

  useEffect(() => {
    if (isSuccess && data) {
      setStatePayment(data);
    }

    return () => {
      queryClient.removeQueries("bankTransactionDetail");
    };
  }, [isSuccess]);

  useEffect(() => {
    setItems(resItem ? resItem : []);

    return () => {
      queryClient.removeQueries("items");
    };
  }, [resItem]);

  useEffect(() => {
    setTaxRates(resTaxRate ? resTaxRate : []);

    return () => {
      queryClient.removeQueries("taxRates");
    };
  }, [resTaxRate]);

  useEffect(() => {
    setAccounts(resAccount?.accounts);

    return () => {
      queryClient.removeQueries("accounts", { exact: true });
    };
  }, [resAccount]);

  const columnItems = useMemo(() => {
    return [
      {
        title: "Item Code",
        dataIndex: "code",
        key: "code",
      },
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
      },
      {
        title: "Quantity",
        dataIndex: "quantity",
        key: "quantity",
      },
      {
        title: "Unit Price",
        dataIndex: "unitPrice",
        key: "unitPrice",
      },
      {
        title: "Account",
        dataIndex: "account",
        key: "account",
      },
      {
        title: "Tax Rate",
        dataIndex: "taxRate",
        key: "taxRate",
      },
      {
        title: "Region",
        dataIndex: "region",
        key: "region",
      },
      {
        title: `Amount ${statePayment?.currencyCode}`,
        dataIndex: "amount",
        key: "amount",
        align: "right"
      },
    ]
  }, [statePayment]);

  const sourceItems = useMemo(() => {
    if (statePayment?.lineItems) {
      return statePayment?.lineItems?.map((x, index) => (
        {
          code: items?.find(i => i.code === x.itemCode)?.name,
          description: x.description,
          quantity: x.quantity,
          unitPrice: x.unitAmount,
          account: accounts?.find(i => i.code === x.accountCode)?.name,
          taxRate: taxRates?.find(i => i.taxType === x.taxType)?.name,
          region: x.tracking?.length > 0 ? x.tracking[0].option : "",
          amount: x.lineAmount,
          key: index
        }
      ))
    }

    return [];
  }, [statePayment]);

  return (
    <div className="body-wrapper">
      <PageTitleWrapper className="title-wrapper">
        <div>
          <h2>Transaction: Spend Money</h2>
        </div>
        <div>
        </div>
      </PageTitleWrapper>
      {
        statePayment && (
          <Row gutter={16}>
            <Col span={24}>
              <StyledWrapper>
                <div className="mb-2">
                  <div className="infobox">
                    <p className="fw-bold">To</p>
                    <p>{statePayment.contact?.name ?? ""}</p>
                  </div>
                  <div className="infobox">
                    <p className="fw-bold">Date</p>
                    <p>{statePayment.date ? formatDMY(statePayment.date) : ""}</p>
                  </div>
                  <div className="infobox">
                    <p className="fw-bold">Reference</p>
                    <p>{statePayment.reference}</p>
                  </div>
                  <div className="infobox">
                    <p className="fw-bold">Amount Types</p>
                    <p>{statePayment.lineAmountTypes}</p>
                  </div>
                  <div className="infobox">
                    <p className="fw-bold">IsReconciled ?</p>
                    <p>{statePayment.isReconciled ? "Yes" : "No"}</p>
                  </div>
                </div>

                <DataTable 
                  columns={columnItems} 
                  sources={sourceItems} 
                  pagination={false}
                />

                <FooterInvoice>
                  <div>
                    <span>Subtotal</span>
                    <span>{statePayment.subTotal}</span>
                  </div>
                  <div>
                    <span>Total Tax</span>
                    <span>{statePayment.totalTax}</span>
                  </div>

                  <StyledDivider />

                  <div>
                    <span className="fw-bold">Total</span>
                    <span>{statePayment.total}</span>
                  </div>
                </FooterInvoice>
              </StyledWrapper>
            </Col>
          </Row>
        )
      }
    </div>
  )
};

export default BankTransactionDetail;
