import { Menu } from "antd";
import { useLocation, useNavigate } from "react-router-dom";

import Logo from "assets/images/logo.png";
import BookKeepingSVG from "assets/icons/bookkeeping.svg";
import RecordKeepingSVG from "assets/icons/recordkeeping.svg";
import CashflowSVG from "assets/icons/cashflow.svg";
import BankSVG from "assets/icons/bank.svg";
import ReceiveableSVG from "assets/icons/receiveable.svg";
import PayableSVG from "assets/icons/payable.svg";
import userSVG from "assets/icons/user.svg";
import settingSVG from "assets/icons/setting.svg";

import { getVersion } from "utils/common";
import { getCurrentUser } from "utils/axiosConfig";

import { StyledNav } from "./styled";

const getItem = ({
  label,
  key,
  icon,
  children,
  type
}) => {
  return {
    key,
    icon,
    children,
    label,
    type,
  }
}

export const menuUsers = [
  getItem({
    label: "Book Keeping",
    key: "bookkeeping",
    icon: (
      <div className="svg-icon">
        <img src={BookKeepingSVG} />
      </div>
    ),
    children: [
      getItem({ label: "Trial Balance", key: "trialbalance"}),
      getItem({ label: "Profit Loss", key: "profitloss" }),
      getItem({ label: "Bank Summary", key: "banksummary" }),
    ]
  }),
  getItem({
    label: "Records Keeping",
    key: "recordkeeping",
    icon: (
      <div className="svg-icon">
        <img src={RecordKeepingSVG} />
      </div>
    ),
    children: [
      getItem({ label: "Invoicing", key: "invoice"}),
      getItem({ label: "Payments", key: "payment" }),
    ]
  }),
  getItem({
    label: "Payable Summary",
    key: "payablesSummary",
    icon: (
      <div className="svg-icon">
        <img src={PayableSVG} />
      </div>
    ),
  }),
  getItem({
    label: "Receiveables Summary",
    key: "receiveablesSummary",
    icon: (
      <div className="svg-icon">
        <img src={ReceiveableSVG} />
      </div>
    ),
  }),
  getItem({
    label: "Cashflow Management",
    key: "cashflow",
    icon: (
      <div className="svg-icon">
        <img src={CashflowSVG} />
      </div>
    ),
  }),
  getItem({
    label: "Bank Reconciliation",
    key: "bankreconciliation",
    icon: (
      <div className="svg-icon">
        <img src={BankSVG} />
      </div>
    ),
  }),
];

export const menuAdmins = [
  getItem({
    label: "Users",
    key: "users",
    icon: (
      <div className="svg-icon">
        <img src={userSVG} />
      </div>
    ),
  }),
  getItem({
    label: "Setting",
    key: "setting",
    icon: (
      <div className="svg-icon">
        <img src={settingSVG} />
      </div>
    ),
  }),
];

const Sidenav = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const page = pathname.replace("/", "");

  let auth = getCurrentUser();
  if (auth) {
    auth = JSON.parse(auth);
  }

  const isAdmin = auth?.roles?.includes("admin");

  const onClick = (e) => {
    let link = isAdmin ? "/admin" : "";
    e.keyPath?.reverse()?.map((key) => {
      link += `/${key}`;
      return false;
    });

    navigate(link);
  }

  const getSelectedKey = () => {
    const splitpage = page.split("/");

    if (splitpage.length > 1) {
      return splitpage[1];
    }
    if (splitpage.length > 0) {
      return splitpage[0];
    }

    return "";
  }

  const getOpenKey = () => {
    const splitpage = page.split("/");

    if (splitpage.length > 1) {
      return splitpage[0];
    }

    return "";
  }

  return (
    <StyledNav>
      <div className="brand">
        <img src={Logo} alt="logo" />
        <span style={{fontSize: "12px", fontWeight: "600", display: "block", marginTop: "5px"}}>Version {getVersion()} </span>
      </div>
      <hr />

      <Menu 
        onClick={onClick}
        style={{ width: "100%" }}
        defaultSelectedKeys={[getSelectedKey()]}
        defaultOpenKeys={[getOpenKey()]}
        mode="inline"
        items={isAdmin ? menuAdmins : menuUsers}
      />
    </StyledNav>
  );
}

export default Sidenav;
