import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

// react-query-config
import queryConfig from "configures/queryConfig";

import App from "./App";

const queryClient = new QueryClient(queryConfig);

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
    {process.env.NODE_ENV !== "production" && <ReactQueryDevtools position="bottom-right" />}
  </QueryClientProvider>,
  document.getElementById("root"),
);
