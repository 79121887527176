import { Modal } from 'antd';
import styled from "styled-components";

export const StyledModal = styled(Modal)`
  .ant-modal-header, .ant-btn-primary, .ant-btn-primary:hover {
    background-color: #219653;
    color: white;
  }

  .ant-btn-primary, .ant-btn-primary:hover {
    border-color: #219653;
  }

  .ant-modal-title, .ant-modal-close {
    color: white;
  }
`;