import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';

import { connect } from "services/xero.service";
import { getXeroToken } from "utils/axiosConfig";

const Home = ({
}) => {
  const navigate = useNavigate();
  const xeroToken = getXeroToken();
  
  useEffect(() => {
    if (!xeroToken) {
      connect()
      .then((res) => {
        if (res.isRedirect) {
          window.location.replace(res.url);
        } else {
          navigate("/bookkeeping/trialbalance");
        }
      })
    }
  }, []);

  return ("");
}


export default Home;
