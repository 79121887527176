import React, { useState } from "react";
import PropTypes from 'prop-types';
import { 
  Input, 
  Select,
  Radio, 
  TimePicker,
  DatePicker,
  AutoComplete,
} from "antd";

import { Wrapper, SelectInTable, StyledDivider, SpaceNewItem } from "./styled";

const { Option, OptGroup } = SelectInTable;
const { TextArea } = Input;

const FormWrapperInput = ({
  label,
  type,
  options,
  error,
  helpertext,
  onChange,
  isNewOption,
  isGroupOption,
  setOpen,
  ...props
}) => {
  const [autoCompleteOptions, setAutoCompleteOptions] = useState(options ? options : []);

  const onSearch = (searchText) => {
    setAutoCompleteOptions(options.filter(x => x.label.includes(searchText)));
  }

  const generateOptions = () => {
    const arr = [];

    if (isGroupOption) {
      options.map((parentOption, index) => {
        const el = (
          <OptGroup label={parentOption.label} key={index}>
            {
              parentOption.children.map((option, childIdx) => (
                <Option key={`${index}-${childIdx}`} value={option.value}>{option.label}</Option>
              ))
            }
          </OptGroup>
        )

        arr.push(el);
        return false;
      })
    } else {
      options.map((option, index) => {
        const el = (
          <Option key={index} value={option.value}>{option.label}</Option>
        );

        arr.push(el);
        return false;
      });
    }

    return arr;
  }

  const renderInput = () => {
    if (type === "select") {
      if (isNewOption === true) {
        return (
          <SelectInTable
            onChange={onChange}
            {...props}
            dropdownRender={menu => (
              <React.Fragment>
                {menu}
                <StyledDivider />
                <SpaceNewItem onClick={() => setOpen(true)}>
                  + New
                </SpaceNewItem>
              </React.Fragment>
            )}
          >
            {generateOptions()}
          </SelectInTable>
        )
      } else {
        return (
          <Select onChange={onChange} {...props}>
            {generateOptions()}
          </Select>
        )
      }
    }

    if (type === "radio") {
      return (
        <Radio.Group onChange={onChange} {...props}>
          {
            options.map((option, index) => (
              <Radio key={index} value={option.toLowerCase()}>{option}</Radio>
            ))
          }
        </Radio.Group>
      )
    }

    if (type === "date") {
      return (
        <DatePicker onChange={onChange} format={"YYYY-MM-DD"} {...props} />
      )
    }

    if (type === "time") {
      return (
        <TimePicker onChange={onChange} {...props} />
      )
    }

    if (type === "textarea") {
      return (
        <TextArea onChange={onChange} rows={4} {...props} />
      )
    }

    if (type === "autocomplete") {
      return (
        <AutoComplete 
          options={autoCompleteOptions}
          onChange={onChange}
          onSelect={onChange}
          onSearch={onSearch}
          {...props}
        />
      )
    }

    return (
      <Input 
        type={type} 
        onChange={onChange} 
        className={error ? "error-input" : ""}
        {...props} 
      />
    )
  }
  return (
    <Wrapper>
      <label>{label}:</label>
      {renderInput()}
      
      {
        error && (
          <div className="error-text">{helpertext}</div>
        )
      }
    </Wrapper>
  )
};

FormWrapperInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  options: PropTypes.array,
  isGroupOption: PropTypes.bool,
  error: PropTypes.bool,
  helpertext: PropTypes.string,
  onChange: PropTypes.func,
};

FormWrapperInput.defaultProps = {
  label: "",
  name: "",
  type: "input",
  options: [],
  isGroupOption: false,
  error: false,
  helpertext: "",
  onChange: () => {}
};


export default FormWrapperInput;
