import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { callback } from "services/xero.service";

const XeroCallBack = ({
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    callback({search: location.search})
    .then((res) => {
      navigate("/bookkeeping/trialbalance");
    })
  }, [location]);

  return ("");
}


export default XeroCallBack;
