import React, { useState, useEffect } from "react";
import * as Yup from 'yup';
import { 
  Row, 
  Col, 
  Switch, 
  Divider,
} from "antd";
import { useFormik, Form, FormikProvider } from 'formik';
import { useQuery, useMutation, QueryClient } from "react-query";
import { CloseCircleOutlined, PrinterOutlined } from "@ant-design/icons";
import { useNavigate, useParams, useLocation } from "react-router-dom";

import PageTitleWrapper from "components/pageTitleWrapper";
import FormWrapperInput from "components/formWrapperInput";
import Button from "components/button";
import DataTable from "components/dataTable";

import { 
  getInvoice,
  getRepeatInvoice,
  getContacts, 
  getItems, 
  getTaxRates, 
  getAccounts,
  getTrackingCategories,
  getCurrencies,
  createInvoice,
  updateInvoice,
  createRepeatingInvoice,
  updateRepeatingInvoice,
  updateContact,
  sendInvoice,
  getInvoiceAsPdf,
} from "services/xero.service";
import { downloadFile } from "utils/common";
import { calcTax } from "utils/formula";
import { formatDMY } from "utils/formatTime";

import NormalInvoiceForm from "./normalInvoice";
import RepeatingInvoiceForm from "./repeatingInvoice";
import PaymentForm from "./payment";
import ModalNewAccount from "./modalNewAccount";
import ModalNewItem from "./modalNewItem";
import ModalNewTaxRate from "./modalNewTaxRate";
import ModalEmailInvoice from "./modalEmailInvoice";

import { 
  InlineBox, 
  StyledDivider, 
  SpaceNewItem, 
  SelectInTable, 
  InputInTable,
  StyledDataTable,
  FooterInvoice,
  WrapperPrinter
} from "./styled";

const { Option, OptGroup } = SelectInTable;

const taxOptions = [
  { label: "Tax Exclusive", value: "Exclusive" },
  { label: "Tax Inclusive", value: "Inclusive" },
  { label: "No Tax", value: "NoTax" },
]

const InvoiceForm = () => {
  const queryClient = new QueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();

  const [showNewItem, setShowNewItem] = useState(false);
  const [showNewAccount, setShowNewAccount] = useState(false);
  const [showNewTax, setShowNewTax] = useState(false);
  const [showEmailInvoice, setShowEmailInvoice] = useState(false);
  const [isRepeating, setIsRepeating] = useState(location.pathname.includes("repeat"));
  const [invoice, setInvoice] = useState({ status: "DRAFT" });
  const [contacts, setContacts] = useState([]);
  const [items, setItems] = useState([]);
  const [taxRates, setTaxRates] = useState([]);
  const [accounts, setAccounts] = useState({});
  const [trackings, setTrackings] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const isDisabled = !isRepeating ? (invoice.status !== "DRAFT" && invoice.status !== "SUBMITTED") : false;

  const { isSuccess: isSuccessInvoice, data: resInvoice } = useQuery(
    ["invoice-edit", { invoiceId: id }], 
    getInvoice, 
    { enabled: (id !== undefined && !location.pathname.includes("repeat")) }
  );
  const { isSuccess: isSuccessRepeat, data: resRepeat } = useQuery(
    ["repeat-invoice-edit", { invoiceId: id }], 
    getRepeatInvoice, 
    { enabled: (id !== undefined && location.pathname.includes("repeat")) }
  );

  const { isSuccess: isSuccessContacts, data: resContact } = useQuery(["contacts"], getContacts, { enabled: (isSuccessInvoice || isSuccessRepeat || id === undefined) });
  const { isSuccess: isSuccessItems, data: resItem, refetch: refetchItem } = useQuery(["items"], getItems, { enabled: isSuccessContacts });
  const { isSuccess: isSuccessTaxRates, data: resTaxRate, refetch: refetchTax } = useQuery(["taxRates"], getTaxRates, { enabled: isSuccessItems });
  const { isSuccess: isSuccessAccounts, data: resAccount, refetch: refetchAccount } = useQuery(["accounts"], getAccounts, { enabled: isSuccessTaxRates });
  const { isSuccess: isSuccessTrackings, data: resTracking } = useQuery(["trackings"], getTrackingCategories, { enabled: isSuccessAccounts });
  const { data: resCurrency } = useQuery(["currencies"], getCurrencies, { enabled: isSuccessTrackings });

  useEffect(() => {
    if (isSuccessInvoice) {
      setInvoice(resInvoice ? resInvoice : { status: "DRAFT" });
    }
    if (isSuccessRepeat) {
      setInvoice(resRepeat ? resRepeat : { status: "DRAFT" });
    }

    return () => {
      queryClient.removeQueries("invoice-edit");
      queryClient.removeQueries("repeat-invoice-edit");
    };
  }, [isSuccessInvoice, resInvoice, isSuccessRepeat, resRepeat]);

  useEffect(() => {
    setContacts(resContact ? resContact : []);

    return () => {
      queryClient.removeQueries("contacts");
    };
  }, [resContact]);

  useEffect(() => {
    setItems(resItem ? resItem : []);

    return () => {
      queryClient.removeQueries("items");
    };
  }, [resItem]);

  useEffect(() => {
    setTaxRates(resTaxRate ? resTaxRate : []);

    return () => {
      queryClient.removeQueries("taxRates");
    };
  }, [resTaxRate]);

  useEffect(() => {
    if (resTracking?.length > 0) {
      const region = resTracking.find(x => x.name === "Region");
      if (region) {
        setTrackings(region.options.map(option => (
          {
            ...option,
            trackingCategoryID: region.trackingCategoryID
          }
        )))
      }
    }

    return () => {
      queryClient.removeQueries("trackings");
    };
  }, [resTracking]);

  useEffect(() => {
    if (resAccount?.accounts) {
      const groupAccounts = resAccount.accounts.reduce((group, account) => {
        const { _class } = account;
        group[_class] = group[_class] ?? [];
        group[_class].push(account);

        return group
      }, {});

      setAccounts(groupAccounts);
    }

    return () => {
      queryClient.removeQueries("accounts", { exact: true });
    };
  }, [resAccount]);

  useEffect(() => {
    setCurrencies(resCurrency);

    return () => {
      queryClient.removeQueries("currencies", { exact: true });
    };
  }, [resCurrency]);

  const serviceSubmitInvoice = useMutation(values => {
    return createInvoice({
      data: {
        invoices: [values]
      }
    });
  });

  const serviceSubmitRepeatingInvoice = useMutation(values => {
    return createRepeatingInvoice({
      data: {
        repeatingInvoices: [values]
      }
    })
  });

  const serviceUpdateInvoice = useMutation(values => {
    return updateInvoice({
      data: {
        invoices: [values]
      },
      invoiceId: id
    });
  });

  const serviceUpdateRepeatingInvoice = useMutation(values => {
    return updateRepeatingInvoice({
      data: {
        repeatingInvoices: [values]
      },
      invoiceId: id
    })
  });

  const serviceUpdateContact = useMutation(values => {
    return updateContact({
      data: {
        contacts: [values]
      },
      contactId: values.contactID
    })
  });

  const serviceSendInvoice = useMutation(_ => {
    return sendInvoice({
      data: {
        invoiceId: id
      },
    })
  });

  const onSubmitInvoice = (values) => {
    const formikValues = {...values};
    formikValues.contact = {
      contactID: contacts.find(x => x.contactID === formikValues.to)?.contactID,
    };
    formikValues.lineItems.map((item, index) => {
      item.tracking = trackings.find(x => x.trackingOptionID === item.region);
      item.lineAmount = Number(calculateLineItemAmount(index));
      delete item.tracking?.name;
      delete item.tracking?.status
      if (formikValues.lineAmountTypes === "NoTax")       {
        delete item.taxType;
        delete item.taxAmount;
      }
    });

    // update contact email address
    const contactValues = {
      contactID: contacts.find(x => x.contactID === values.to)?.contactID,
      emailAddress: formikValues.emailAddress
    };


    if (!isRepeating) {
      // normal invoice
      const reuseSubmitInvoice = () => {
        if (!id) {
          // create invoice
          serviceSubmitInvoice.mutate(formikValues, {
            onSuccess: (res) => {
              if (res) {
                navigate("/recordkeeping/invoice");
              }
            }
          });
        } else {
          // update invoice
          serviceUpdateInvoice.mutate(formikValues, {
            onSuccess: (res) => {
              if (res) {
                navigate("/recordkeeping/invoice");
              }
            }
          });
        }
      }

      if (formikValues.approvedForSending) {
        serviceUpdateContact.mutate(contactValues, {
          onSuccess: (res) => {
            if (res) {
              serviceSendInvoice.mutate({}, {
                onSuccess: (res) => {
                  setShowEmailInvoice(false);
                  navigate("/recordkeeping/invoice");
                }
              });
            }
          }
        });
      } else {
        reuseSubmitInvoice();
      }
    } else {
      // repeating invoice
      const reuseSubmitRepeating = () => {
        if (!id) {
          serviceSubmitRepeatingInvoice.mutate(formikValues, {
            onSuccess: (res) => {
              if (res) {
                navigate("/recordkeeping/invoice");
              }
            }
          });
        } else {
          formikValues.repeatingInvoiceID = id;
          formikValues.iD = invoice.iD;
          formikValues.status = "DELETED";
  
          serviceUpdateRepeatingInvoice.mutate(formikValues, {
            onSuccess: (res) => {
              if (res) {
                formikValues.status = values.status;
                serviceSubmitRepeatingInvoice.mutate(formikValues, {
                  onSuccess: (res) => {
                    if (res) {
                      navigate("/recordkeeping/invoice");
                    }
                  }
                });
              }
            }
          });
        }
      }

      if (formikValues.approvedForSending === true) {
        // update account
        serviceUpdateContact.mutate(contactValues, {
          onSuccess: (res) => {
            if (res) {
              reuseSubmitRepeating();
            }
          }
        });
      } else {
        reuseSubmitRepeating();
      }
    }
  }

  const getInvoicePdf = () => {
    getInvoiceAsPdf({invoiceId: id})
    .then((res) => {
      if (res) {
        downloadFile(`${invoice.invoiceNumber ? invoice.invoiceNumber : "invoice"}.pdf`, res);
      }
    })
  }

  let InvoiceSchema = Yup.object().shape({
    to: Yup.string().required('To is required'),
  });

  const formik = useFormik({
    initialValues: {
      type: invoice.type ? invoice.type : "ACCREC", // Journal Type
      to: invoice.contact?.contactID ?? "",
      emailAddress: contacts.find(x => x.contactID === invoice.contact?.contactID)?.emailAddress ?? "",
      date: invoice.date ? invoice.date : "",
      dueDate: invoice.dueDate ? invoice.dueDate : "",
      lineItems: invoice.lineItems ? invoice.lineItems?.map((item, index) => (
        {
          ...item,
          region: item.tracking?.trackingOptionID,
          index: index,
          key: index
        }
      )) : [],
      reference: invoice.reference,
      lineAmountTypes: invoice.lineAmountTypes,
      currencyCode: invoice.currencyCode,
      schedule: invoice.schedule, // repeat
      approvedForSending: invoice.approvedForSending, // repeat
      hasAttachments: invoice.hasAttachments, // repeat
      includePDF: invoice.includePDF, // repeat
      markAsSent: invoice.markAsSent, // repeat
      sendCopy: invoice.sendCopy, // repeat
      status: invoice.status, // Status Invoice
    },
    validationSchema: InvoiceSchema,
    enableReinitialize: true,
    onSubmit: (values) => onSubmitInvoice(values)
  });

  const { errors, touched, values, handleSubmit, setFieldValue, getFieldProps } = formik;

  const columnItems = [
    {
      title: "Item",
      dataIndex: "itemCode",
      key: "itemCode",
      width: 200,
      render: (_, record) => {
        const itemCode = getFieldProps(`lineItems.${record.index}.itemCode`).value;

        return (
          <SelectInTable
            placeholder="Select Item"
            bordered={false}
            style={{maxWidth: "170px"}}
            value={itemCode}
            onChange={e => setFieldValue(`lineItems.${record.index}.itemCode`, e)}
            dropdownClassName="modify-options"
            disabled={isDisabled}
            dropdownRender={menu => (
              <React.Fragment>
                {menu}
                <StyledDivider />
                <SpaceNewItem onClick={() => setShowNewItem(true)}>
                  + New Item
                </SpaceNewItem>
              </React.Fragment>
            )}
          >
            {
              items.map((option, index) => (
                <Option key={index} value={option.code}>{`${option.code}: ${option.name}`}</Option>
              ))
            }
          </SelectInTable>
        )
      }
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render:(_, record) => {
        const description = getFieldProps(`lineItems.${record.index}.itemCode`).value;

        return (
          <InputInTable 
            placeholder="Description" 
            bordered={false} 
            value={description}
            onChange={e => setFieldValue(`lineItems.${record.index}.description`, e.target.value)}
            disabled={isDisabled}
          />
        )
      }
    },
    {
      title: "Qty",
      dataIndex: "quantity",
      key: "quantity",
      width: 100,
      render:(_, record) => {
        const quantity = getFieldProps(`lineItems.${record.index}.quantity`).value;

        return (
          <InputInTable 
            type="number"
            placeholder="0" 
            bordered={false} 
            style={{maxWidth: "70px"}}
            value={quantity}
            onChange={e => setFieldValue(`lineItems.${record.index}.quantity`, e.target.value)}
            disabled={isDisabled}
          />
        )
      }
    },
    {
      title: "Unit Price",
      dataIndex: "unitAmount",
      key: "unitAmount",
      width: 135,
      render:(_, record) => {
        const unitAmount = getFieldProps(`lineItems.${record.index}.unitAmount`).value;

        return (
          <InputInTable 
            type="number"
            placeholder="0" 
            bordered={false} 
            style={{maxWidth: "105px"}}
            value={unitAmount}
            onChange={e => setFieldValue(`lineItems.${record.index}.unitAmount`, e.target.value)}
            disabled={isDisabled}
          />
        )
      }
    },
    {
      title: "Disc %",
      dataIndex: "discountRate",
      key: "discountRate",
      width: 100,
      render:(_, record) => {
        const discountRate = getFieldProps(`lineItems.${record.index}.discountRate`).value;

        return (
          <InputInTable 
            type="number"
            placeholder="0" 
            bordered={false} 
            style={{maxWidth: "70px"}}
            value={discountRate}
            onChange={e => setFieldValue(`lineItems.${record.index}.discountRate`, e.target.value)}
            disabled={isDisabled}
          />
        )
      }
    },
    {
      title: "Account",
      dataIndex: "accountCode",
      key: "accountCode",
      width: 250,
      render: (_, record) => {
        const accountCode = getFieldProps(`lineItems.${record.index}.accountCode`).value;

        return (
          <SelectInTable
            placeholder="Select Account"
            bordered={false}
            style={{maxWidth: "220px"}}
            value={accountCode}
            disabled={isDisabled}
            onChange={e => {
              const mergedAccounts = [].concat.apply([], Object.values(accounts))
              const selectedAccount = mergedAccounts.find(x => x.code === e);
              if (selectedAccount) {
                setFieldValue(`lineItems.${record.index}.taxType`, selectedAccount.taxType);
              }
              setFieldValue(`lineItems.${record.index}.accountCode`, e);
            }}
            dropdownClassName="modify-options"
            dropdownRender={menu => (
              <React.Fragment>
                {menu}
                <StyledDivider />
                <SpaceNewItem onClick={() => setShowNewAccount(true)}>
                  + New Account
                </SpaceNewItem>
              </React.Fragment>
            )}
          >
            {
              Object.keys(accounts).map((key, index) => (
                <OptGroup label={key} key={index}>
                  {
                    accounts[key].map((account, childIdx) => (
                      <Option key={`${index}-${childIdx}`} value={account.code}>{account.name}</Option>
                    ))
                  }
                </OptGroup>
              ))
            }
          </SelectInTable>
        )
      }
    },
    {
      title: "Tax Rate",
      dataIndex: "taxType",
      key: "taxType",
      width: 180,
      render: (_, record) => {
        const taxType = getFieldProps(`lineAmountTypes`).value;
        const lineTaxType = getFieldProps(`lineItems.${record.index}.taxType`).value;

        return (
          <SelectInTable
            placeholder="Select Tax Rate"
            bordered={false}
            style={{display: (taxType === "NoTax") ? "none" : "block", maxWidth: "150px"}}
            onChange={e => setFieldValue(`lineItems.${record.index}.taxType`, e)}
            value={lineTaxType}
            disabled={isDisabled}
            dropdownClassName="modify-options"
            dropdownRender={menu => (
              <React.Fragment>
                {menu}
                <StyledDivider />
                <SpaceNewItem onClick={() => setShowNewTax(true)}>
                  + New Tax Rate
                </SpaceNewItem>
              </React.Fragment>
            )}
          >
            {
              getFilterTaxRates(record.index).map((option, index) => (
                <Option key={index} value={option.taxType}>{`${option.name} (${option.displayTaxRate}%)`}</Option>
              ))
            }
          </SelectInTable>
        )
      }
    },
    {
      title: "Region",
      dataIndex: "region",
      key: "region",
      width: 180,
      render: (_, record) => {
        const region = getFieldProps(`lineItems.${record.index}.region`).value;

        return (
          <SelectInTable
            placeholder="Select Region"
            bordered={false}
            style={{maxWidth: "150px"}}
            value={region}
            disabled={isDisabled}
            onChange={e => setFieldValue(`lineItems.${record.index}.region`, e)}
          >
            {
              trackings.map((option, index) => (
                <Option key={index} value={option.trackingOptionID}>{option.name}</Option>
              ))
            }
          </SelectInTable>
        )
      }
    },
    {
      title: "Amount",
      dataIndex: "lineAmount",
      key: "lineAmount",
      width: 135,
      render: (_, record) => {
        return calculateLineItemAmount(record.index);
      }
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      width: 60,
      render: (_, record) => {
        return (
          !isDisabled && (
            <CloseCircleOutlined onClick={() => removeItem(record.index)} />
          )
        )
      }
    },
  ];

  const getFilterTaxRates = (index) => {
    const accountCode = getFieldProps(`lineItems.${index}.accountCode`).value;
    const mergedAccounts = [].concat.apply([], Object.values(accounts))
    const selectedAccount = mergedAccounts.find(x => x.code === accountCode);
    if (selectedAccount) {
      if (selectedAccount._class === "ASSET") {
        return taxRates.filter(x => x.canApplyToAssets === true);
      }
      if (selectedAccount._class === "EQUITY") {
        return taxRates.filter(x => x.canApplyToEquity === true);
      }
      if (selectedAccount._class === "EXPENSE") {
        return taxRates.filter(x => x.canApplyToExpenses === true);
      }
      if (selectedAccount._class === "LIABILITY") {
        return taxRates.filter(x => x.canApplyToLiabilities === true);
      }
      if (selectedAccount._class === "REVENUE") {
        return taxRates.filter(x => x.canApplyToRevenue === true);
      }
    }

    return taxRates;
  }

  const reAdjustSourceItems = () => {
    const items = getFieldProps("lineItems").value;
    return items.map((item, index) => (
      {
        key: index,
        ...item
      }
    ))
  } 

  const addItem = () => {
    const items = getFieldProps("lineItems").value;
    const updatedItems = [...items];
    updatedItems.push({
      index: updatedItems.length,
      key: updatedItems.length,
      quantity: 0,
      unitAmount: 0
    });

    setFieldValue("lineItems", updatedItems);
  }

  const removeItem = (index) => {
    const items = getFieldProps("lineItems").value;
    const updatedItems = [...items];
    setFieldValue("lineItems", updatedItems.filter(x => x.index !== index));
  }

  const getSubTotal = () => {
    const items = getFieldProps("lineItems").value;
    let total = 0;

    items.map((_, index) => {
      total += Number(calculateLineItemAmount(index));
      return false;
    });

    return total.toFixed(2);
  }

  const getFooterTaxRate = () => {
    const values = [];
    const lineAmountType = getFieldProps("lineAmountTypes").value;
    const items = getFieldProps("lineItems").value;
    items.map((item) => {
      const selectedTax = taxRates.find(x => x.taxType === item.taxType);
      if (selectedTax) {
        const taxAmount = calcTax(item.quantity, item.unitAmount, item.discountRate, selectedTax.effectiveRate, lineAmountType)
        const selectedPushed = values.find(x => x.name === `Tax ${selectedTax.displayTaxRate}%`);
        if (selectedPushed) {
          selectedPushed.value += taxAmount;
        } else {
          values.push({
            name: `Tax ${selectedTax.displayTaxRate}%`,
            value: taxAmount
          })
        }
      }
      return false;
    });

    return values;
  }

  const getTotal = () => {
    const lineAmountType = getFieldProps("lineAmountTypes").value;
    const subtotal = Number(getSubTotal());
    let taxAmount = 0;
    if (lineAmountType === "Exclusive") {
      taxAmount = getFooterTaxRate().reduce((acc, obj) => { return acc + obj.value }, 0);
    }

    return (subtotal + taxAmount).toFixed(2);
  }

  const calculateLineItemAmount = (index) => {
    const qty = getFieldProps(`lineItems.${index}.quantity`).value;
    const unitAmount = getFieldProps(`lineItems.${index}.unitAmount`).value;
    const disc = getFieldProps(`lineItems.${index}.discountRate`).value;

    if (!qty || !unitAmount) {
      return 0;
    }

    const subTotal = Number(qty) * Number(unitAmount);
    const discAmount = disc ? Number(disc) / 100 * subTotal : 0;
    const total = (subTotal - discAmount).toFixed(2);

    return total;
  }

  return (
    <React.Fragment>
      <div className="body-wrapper">
        <PageTitleWrapper className="title-wrapper">
          <div>
            <h2>Create Invoice</h2>
          </div>
          <WrapperPrinter>
            {/* <InvoicePDF 
              invoice={invoice}
              subTotal={getSubTotal()}
              total={getTotal()}
              footerTaxs={getFooterTaxRate()}
              payments={invoice?.payments ? invoice?.payments : []}
            /> */}
            <PrinterOutlined onClick={() => getInvoicePdf()} />
          </WrapperPrinter>
        </PageTitleWrapper>
          
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit} id="pdfWrapper">
            <Row gutter={16}>
              <Col span={24}>
                <Switch 
                  checkedChildren="Repeating Invoice"
                  unCheckedChildren="Normal Invoice"
                  defaultChecked={isRepeating}
                  onChange={e => setIsRepeating(e)}
                  disabled={isDisabled}
                />
              </Col>
              <Col span={24}>
                <InlineBox>
                  <FormWrapperInput 
                    label="Invoice To"
                    name="to"
                    type="autocomplete"
                    options={contacts?.map(x => ({label: x.name, value: x.contactID}))}
                    value={contacts?.find(x => x.contactID === values.to)?.name}
                    onChange={(e) => setFieldValue("to", e)}
                    error={Boolean(touched.to && errors.to)}
                    helpertext={touched.to && errors.to}
                    disabled={isDisabled}
                  />
                </InlineBox>
                <InlineBox>
                  <FormWrapperInput 
                    label="Reference"
                    name="reference"
                    type="input"
                    value={values.reference}
                    onChange={(e) => setFieldValue("reference", e.target.value)}
                    disabled={isDisabled}
                  />
                </InlineBox>
              </Col>

              {
                isRepeating ? (
                  <RepeatingInvoiceForm 
                    values={values}
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    isDisabled={isDisabled}
                  />
                ) : (
                  <NormalInvoiceForm 
                    values={values}
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    isDisabled={isDisabled}
                  />
                )
              }

              <Col span={24}>
                <InlineBox>
                  <FormWrapperInput 
                    label="Currency"
                    name="currencyCode"
                    type="select"
                    value={values.currencyCode}
                    options={currencies?.map((currency) => (
                      {
                        label: `${currency.code} ${currency.description}`,
                        value: currency.code,
                      }
                    ))}
                    onChange={(e) => setFieldValue("currencyCode", e)}
                    disabled={isDisabled}
                  />
                </InlineBox>
                <InlineBox>
                  <FormWrapperInput 
                    label="Amount Tax Type"
                    name="lineAmountTypes"
                    type="select"
                    value={values.lineAmountTypes}
                    options={taxOptions}
                    onChange={(e) => setFieldValue("lineAmountTypes", e)}
                    disabled={isDisabled}
                  />
                </InlineBox>
              </Col>

              <Col span={24} className="mt-3">
                <div className="text-right">
                  {
                    !isDisabled && (
                      <Button 
                        className="mb-2" 
                        name="Add Item" 
                        onClick={() => addItem()}
                      />
                    )
                  }
                </div>
                
                <StyledDataTable>
                  <DataTable 
                    columns={columnItems} 
                    sources={reAdjustSourceItems()} 
                    pagination={false}
                  />
                </StyledDataTable>
              </Col>

              <Col span={24} className="gutter-row mt-2">
                <FooterInvoice>
                  <div>
                    <span>Subtotal</span>
                    <span>{getSubTotal()}</span>
                  </div>

                  {
                    getFooterTaxRate().map((item, index) => (
                      <div key={index}>
                        <span>{item.name}</span>
                        <span>{item.value}</span>
                      </div>
                    ))
                  }
                  
                  <Divider />
                  <div>
                    <span className="fw-bold">Total</span>
                    <span>{getTotal()}</span>
                  </div>

                  <Divider />
                  {
                    invoice.payments?.map((payment, index) => (
                      <div key={index}>
                        <span>Payment ({formatDMY(payment.date)})</span>
                        <span>{payment.amount}</span>
                      </div>
                    ))
                  }

                  <div>
                    <span className="fw-bold">Amount Due</span>
                    <span>{invoice?.amountDue ?? 0}</span>
                  </div>
                </FooterInvoice>
              </Col>

              {
                !isRepeating ? (
                  !isDisabled && (
                    <Col span={24}>
                      {
                        id && (
                          <Button 
                            className="mt-2 mr-2" 
                            name="Email" 
                            onClick={() => {
                              setFieldValue("approvedForSending", true);
                              setShowEmailInvoice(true);
                            }}
                            isDefault={true}
                            type="button"
                          />
                        )
                      }

                      {
                        invoice.status !== "SUBMITTED" && (
                          <React.Fragment>
                            <Button 
                              className="mt-2 mr-2" 
                              name="Save as draft" 
                              onClick={() => setFieldValue("status", "DRAFT")}
                              htmlType="submit"
                            />
                            
                            <Button 
                              className="mt-2 mr-2" 
                              name="Submit for approval" 
                              onClick={() => setFieldValue("status", "SUBMITTED")}
                              htmlType="submit"
                            />
                          </React.Fragment>
                        )
                      }
  
                      <Button 
                        className="mt-2" 
                        name="Approve" 
                        onClick={() => setFieldValue("status", "AUTHORISED")}
                        htmlType="submit"
                      />
                    </Col>
                  )
                ) : (
                  <Button 
                    className="mt-2 mr-2" 
                    name="Save" 
                    htmlType="submit"
                  />
                )
              }
                
            </Row>
          </Form>
        </FormikProvider>

        {
          (!isRepeating && invoice.status === "AUTHORISED") && (
            <PaymentForm 
              invoiceId={id}
              total={Number(getTotal())}
              accounts={accounts}
              setShowEmailInvoice={setShowEmailInvoice}
              setInvoiceField={setFieldValue}
            />
          )
        }
      </div>

      <ModalNewItem
        open={showNewItem}
        accounts={accounts}
        taxRates={taxRates}
        refetchItem={refetchItem}
        setOpen={setShowNewItem}
        setShowNewAccount={setShowNewAccount}
      />
      <ModalNewAccount
        open={showNewAccount}
        taxRates={taxRates}
        refetchAccount={refetchAccount}
        setOpen={setShowNewAccount}
      />
      <ModalNewTaxRate
        open={showNewTax}
        refetchTax={refetchTax}
        setOpen={setShowNewTax}
      />
      <ModalEmailInvoice 
        open={showEmailInvoice}
        values={values}
        setOpen={setShowEmailInvoice}
        setFieldValue={setFieldValue}
        handleSubmit={handleSubmit}
      />
    </React.Fragment>
  )
};

export default InvoiceForm;
