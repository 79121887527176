import React, { useState, useEffect } from "react";
import { useMutation, useQuery, QueryClient } from "react-query";

import PageTitleWrapper from "components/pageTitleWrapper";
import { getUser, updateUser } from "services/user.service";
import { getCurrentUser } from "utils/axiosConfig";

import UserForm from "pages/admin/user/userForm";

const MyAccount = () => {
  const queryClient = new QueryClient();

  const jsonUser = getCurrentUser();
  const currentUser = jsonUser ? JSON.parse(jsonUser) : null;
  const id = currentUser?.uId;
  const { isSuccess: isSuccessUser, data: resUser } = useQuery(["user-edit", { userId: id }], getUser);

  const [userState, setUserState] = useState({});
  const [finishLoad, setFinishLoad] = useState(false);

  useEffect(() => {
    if (isSuccessUser && resUser) {
      setUserState({
        _id: resUser._id,
        userName: resUser.userName,
        nationalId: resUser.nationalId,
        email: resUser.email,
        contactNo: resUser.contactNo,
        country: resUser.country,
        state: resUser.state,
        city: resUser.city,
        postalCode: resUser.postalCode,
        address: resUser.address,
        gender: resUser.gender,
        dateOfBirth: resUser.dateOfBirth,
      });
      setFinishLoad(true);
    }
  }, [isSuccessUser, resUser]);

  const submitUser = useMutation(values => {
    return updateUser({values, userId: id});
  });

  const onSubmitUser = (values) => {
    delete values._id;

    submitUser.mutate(values, {
      onSuccess: (res) => {
        if (res) {
          queryClient.setQueriesData(["user-edit", { userId: id }], res)
        }
      }
    });
  }

  return (
    finishLoad && (
      <div className="body-wrapper">
      <PageTitleWrapper className="title-wrapper">
        <div>
          <h2>My Account</h2>
        </div>
        <div className="search-input">
        </div>
      </PageTitleWrapper>

      <UserForm 
        initialValues={userState}
        isEdit={true}
        onSubmit={onSubmitUser}
      />
    </div>
    )
  )
};

export default MyAccount;
