import React from "react";
import PropTypes from "prop-types";

import DefaultModal from "components/defaultModal";
import FormWrapperInput from "components/formWrapperInput";

const ModalEmailInvoice = ({
  open,
  setOpen,
  values,
  setFieldValue,
  handleSubmit,
}) => {
  return (
    <DefaultModal
      open={open}
      title="Send Invoice"
      handleOk={handleSubmit}
      handleClose={() => {
        setOpen(false);
        setOpen(false);
      }}
    >
      <FormWrapperInput 
        label="Email Address"
        name="emailAddress"
        type="input"
        value={values.emailAddress}
        onChange={(e) => setFieldValue("emailAddress", e.target.value)}
      />
    </DefaultModal>
  )
};

ModalEmailInvoice.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  values: PropTypes.object,
  setFieldValue: PropTypes.func,
  handleSubmit: PropTypes.func,
};

ModalEmailInvoice.defaultProps = {
  open: false,
  setOpen: () => {},
  values: {},
  setFieldValue: () => {},
  handleSubmit: () => {},
};

export default ModalEmailInvoice;
