import { Menu } from "antd";
import styled from "styled-components";
import Button from "components/button";

export const StyledNav = styled.div`
  color: rgb(97, 97, 97);

  .ant-menu-submenu,
  .ant-menu-submenu-title,
  .ant-menu-item {
    display: flex !important;
    align-items: center !important;
    height: 46px !important;
    margin: 5px 0px 5px 0px !important;
    border-radius: 12px;

    .ant-menu-sub {
      display: none;
    }
  }

  .ant-menu-submenu-title {
    border-radius: 12px;
  }

  .ant-menu-submenu-open {
    height: auto !important; 
    display: block !important;

    .ant-menu-sub {
      display: block;
    }
  }

  .ant-menu-item {
    padding-left: 24px !important;
  }

  .ant-menu-sub {
    li {
      padding-left: 48px !important;
    }
  }

  .svg-icon {
    background-color: white;
    display: inline-flex;
    padding: 6px;
    border-radius: 8px;
  }

  .ant-menu-submenu-selected .ant-menu-submenu-title,
  .ant-menu-item-selected {
    background-color: #219653 !important;
    color: white;

    .ant-menu-submenu-arrow {
      color: white;
    }
  }

  .ant-menu-submenu-selected .ant-menu-submenu-title {
    display: flex;
    align-items: center;
    height: 46px;
  }

  .ant-menu-submenu:hover .ant-menu-submenu-title,
  .ant-menu-item:hover {
    background-color: #219653 !important;
    display: flex;
    color: white;

    .ant-menu-submenu-arrow {
      color: white;
    }
  }

  .ant-menu-submenu .ant-menu-item:hover,
  .ant-menu-submenu-selected .ant-menu-item-selected {
    background-color: white !important;
    color: #219653 !important;
  }
`;

export const ContentWrapper = styled.div`
  .title-wrapper {
    margin-bottom: 20px;
    
    h2 {
      font-weight: bold;
      margin-bottom: 0px;
    }

    .ant-picker, 
    .ant-select-selector,
    .ant-input {
      border-radius: 25px;
      height: 40px;
    }

    .ant-select-selection-search {
      height: 40px;
    }
    
    .ant-select-selection-item {
      line-height: 38px;
    }

    .anticon-plus {
      font-size: 22px;
      position: relative;
      top: 2px;
      margin-left: 20px;
      cursor: pointer;
    }
  }

  .search-input {
    .anticon-search {
      position: relative;
      left: 25px;
      z-index: 1000;
    }

    .ant-input {
      padding-left: 30px;
    }
  }

  .body-wrapper {
    padding: 20px;
    border-radius: 10px;
    background-color: white;
  }
`;

export const RightBar = styled.div`
  img {
    cursor: pointer;
  }
`;

export const StyledMenu = styled(Menu)`
  border-radius: 10px;
  padding: 15px;

  .ant-dropdown-menu-item {
    width: 165px;
    padding: 8px 0px 8px 8px;
    border-radius: 10px;
  }

  .ant-dropdown-menu-title-content span {
    width: 100%;
    height: 100%;
    display: block;
  }
`;

export const StyledSwtichButton = styled(Button)`
  margin-right: 15px;
  svg {
    position: relative;
    top: -2px;
  }
  svg path {
    fill: white !important;
  }
`;