import axios from "axios";
import { API_URL } from "configures/setting";

export const getXeroToken = () => localStorage.getItem("xeroToken");
export const getToken = () => localStorage.getItem("accessToken");
export const getCurrentUser = () => localStorage.getItem("currentUser");
export const getRoles = () => localStorage.getItem("roles");
export const getOrgId = () => localStorage.getItem("orgid");

const customAxios = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

customAxios.interceptors.request.use(
  request => {
    request.headers.Authorization = `${getToken()}`;
    request.headers.orgid = getOrgId();
    return request;
  },
  err => {
    return Promise.reject(err);
  },
);

export default customAxios;
