import moment from "moment";

export const formatDMY = (value) => {
  if (!value) {
    return value;
  }

  return moment(value).format("DD-MMM-YYYY");
}

export const formatInputDate = (date) => {
  return moment(date, 'YYYY-MM-DD');
}

export const formatHMS = (value) => {
  if (!value) {
    return value;
  }

  return moment(value, 'HH:mm:ss')
}