import { round } from "lodash";

export const calcTax = (qty, unitAmount, disc, tax, type) => {
  if (!qty || !unitAmount)
    return 0;

  const n_qty = Number(qty);
  const n_unitAmount = Number(unitAmount);
  const n_amount = n_qty * n_unitAmount;
  const n_disc = disc ? Number(disc) / 100 : 0;
  const n_discAmount = disc ? n_amount * n_disc : 0;
  const n_tax = Number(tax) / 100;

  if (type === "Inclusive") {
    return round(((n_amount - n_discAmount) - ((n_amount - n_discAmount) / (1 + n_tax))), 2);
  }

  if (type === "Exclusive") {
    return round((n_amount - n_discAmount) * n_tax, 2);
  }

  return 0;
}