import React, { useMemo, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery, QueryClient } from "react-query";
import { Row, Col } from "antd";
import { EyeOutlined } from '@ant-design/icons';

import { getPayment } from "services/xero.service";
import PageTitleWrapper from "components/pageTitleWrapper";
import DataTable from "components/dataTable";
import { formatDMY } from "utils/formatTime";

import { StyledWrapper, FooterInvoice, StyledDivider } from "./styled";

const PaymentDetail = () => {
  const queryClient = new QueryClient();
  const navigate = useNavigate();

  const { id } = useParams();
  const [statePayment, setStatePayment] = useState(null);

  const { isSuccess, data } = useQuery(["paymentDetail", { paymentId: id }], getPayment);

  useEffect(() => {
    if (isSuccess && data) {
      setStatePayment(data);
    }

    return () => {
      queryClient.removeQueries("paymentDetail");
    };
  }, [isSuccess]);

  const columnItems = useMemo(() => {
    return [
      {
        title: "Contact",
        dataIndex: "contact",
        key: "contact",
      },
      {
        title: "Invoice No",
        dataIndex: "invoiceNo",
        key: "invoiceNo",
      },
      {
        title: "Date",
        dataIndex: "date",
        key: "date",
        render: (_, record) => formatDMY(record.date)
      },
      {
        title: "Due Date",
        dataIndex: "dueDate",
        key: "dueDate",
        render: (_, record) => formatDMY(record.dueDate)
      },
      {
        title: "Total",
        dataIndex: "total",
        key: "total",
      },
      {
        title: `Payment Amount`,
        dataIndex: "paymentAmount",
        key: "paymentAmount",
        align: "right"
      },
      {
        title: "Action",
        dataIndex: "action",
        key: "action",
        width: "80px",
        render: (_, record) => {
          return (
            <div className="icon-wrapper">
              <EyeOutlined 
                onClick={() => {
                  navigate(`/recordkeeping/invoice/${record.invoiceId}`)
                }}
              />
            </div>
          )
        }
      },
    ]
  }, [statePayment]);

  const sourceItems = useMemo(() => {
    if (statePayment) {
      return [
        {
          contact: statePayment.invoice.contact?.name,
          invoiceNo: statePayment.invoice?.invoiceNumber,
          date: statePayment.invoice?.date ? formatDMY(statePayment.invoice?.date) : "",
          dueDate: statePayment.invoice?.dueDate ? formatDMY(statePayment.invoice?.dueDate) : "",
          total: statePayment.invoice?.total,
          paymentAmount: statePayment.invoice?.amountPaid,
          invoiceId: statePayment.invoice?.invoiceID,
          key: 1,
        }
      ]
    }

    return [];
  }, [statePayment]);

  return (
    <div className="body-wrapper">
      <PageTitleWrapper className="title-wrapper">
        <div>
          <h2>Transaction: Payment</h2>
        </div>
        <div>
        </div>
      </PageTitleWrapper>
      {
        statePayment && (
          <Row gutter={16}>
            <Col span={24}>
              <StyledWrapper>
                <div className="mb-2">
                  <div className="infobox">
                    <p className="fw-bold">Date</p>
                    <p>{statePayment.date ? formatDMY(statePayment.date) : ""}</p>
                  </div>
                  <div className="infobox">
                    <p className="fw-bold">Reference</p>
                    <p>{statePayment.reference !== "" ? statePayment.reference : "-"}</p>
                  </div>
                  <div className="infobox">
                    <p className="fw-bold">IsReconciled ?</p>
                    <p>{statePayment.isReconciled === true ? "Yes" : "No"}</p>
                  </div>
                </div>

                <DataTable 
                  columns={columnItems} 
                  sources={sourceItems} 
                  pagination={false}
                />

                <FooterInvoice>
                  <StyledDivider />
                  <div>
                    <span className="fw-bold">Total</span>
                    <span>{statePayment.amount}</span>
                  </div>
                </FooterInvoice>
              </StyledWrapper>
            </Col>
          </Row>
        )
      }
    </div>
  )
};

export default PaymentDetail;
