import React from "react";
import { Button } from "antd";
import PropTypes from "prop-types";

import styled from "styled-components";

const StyledButton = styled(Button)`
  background-color: #219653;
  color: white;

  :hover, :active, :focus {
    background-color: #219653;
    color: white;
  }
`;

const WhiteButton = styled(Button)`
  color: #219653;
  border-color: #219653;

  :hover, :active, :focus {
    color: #219653;
    border-color: #219653;
  }
`;

const DefaultButton = ({
  name,
  isDefault,
  onClick,
  ...props
}) => {
  return (
    !isDefault ? (
      <StyledButton 
        onClick={onClick} 
        {...props}
      >
        {name}
      </StyledButton>
    ) : (
      <WhiteButton 
        onClick={onClick} 
        {...props}
      >
        {name}
      </WhiteButton>
    )
  )
};

DefaultButton.propTypes = {
  name: PropTypes.string,
  isDefault: PropTypes.bool,
  onClick: PropTypes.func,
};

DefaultButton.defaultProps = {
  name: "Save",
  isDefault: false,
  onClick: () => {},
};

export default DefaultButton;
