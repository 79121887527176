import React from "react";

import { Wrapper } from './styled';

const PageTitleWrapper = ({
  children,
  ...props
}) => {
  return (
    <Wrapper {...props}>
      {children}
    </Wrapper>
  )
};

export default PageTitleWrapper;
