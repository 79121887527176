import React, { useMemo, useState, useEffect } from "react";
import { DatePicker } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import moment from "moment";

import PageTitleWrapper from "components/pageTitleWrapper";
import DataTable from "components/dataTable";
import Button from "components/button";
import Loading from "components/loading";
import { getTrialBalance } from "services/xero.service";
import { removeSpace, exportXlsx } from "utils/common";
import { formatDMY } from "utils/formatTime";
import { thousandSepartors } from "utils/formatNumber";

const TrialBalance = () => {
  const [reportInfo, setReportInfo] = useState(null);
  const [trialBalance, setTrialBalance] = useState([]);
  const [filterDate, setFilterDate] = useState(moment());
  const [finishLoad, setFinishLoad] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);

  useEffect(() => {
    getTrialBalance({
      date: formatDMY(filterDate)
    })
    .then((res) => {
      if (res.reports.length > 0) {
        const report = res.reports[0];
        setReportInfo({
          reportName: report.reportTitles.length > 0 ? report.reportTitles[0] : "",
          reportOrg: report.reportTitles.length > 1 ? report.reportTitles[1] : "",
          reportDate: report.reportTitles.length > 2 ? report.reportTitles[2] : ""
        })
        setTrialBalance(report.rows);
        setFinishLoad(true);
      }
    })
  }, [isUpdate]);

  const renderColumn = (record, field) => {
    const getValue = () => {
      if (field === "Account")
        return record[field];
      else
        return thousandSepartors(record[field], 0);
    }

    return (
      <span className={record.fwbold ? "fw-bold" : ""}>{getValue()}</span>
    );
  }

  const columnTrialBalance = useMemo(() => {
    if (trialBalance.length > 0) {
      return trialBalance[0].cells.map((cell, cellIdx) => {
        const field = removeSpace(cell.value);

        return {
          title: cell.value,
          dataIndex: field,
          key: field,
          width: cellIdx === 0 ? "30%" : "",
          render: (_, record) => renderColumn(record, field)
        }
      })
    } 

    return [];
  }, [trialBalance]);

  const sourceTrialBalance = useMemo(() => {
    const arr = [];
    if (columnTrialBalance.length > 0) {
      for (let i = 1; i <= trialBalance.length - 1; i++) {
        const childrenRows = [];
        const total = {};

        if (i === trialBalance.length - 1) {
          // total 
          columnTrialBalance.map((column, columnIdx) => {
            total[column.key] = trialBalance[i].rows.length > 0 ? trialBalance[i].rows[0].cells[columnIdx].value : "";
          });
        } else {
          // add children values
          trialBalance[i].rows.map((childRow, childRowIdx) => {
            const rowValue = {};
            columnTrialBalance.map((column, columnIdx) => {
              rowValue[column.key] = childRow.cells[columnIdx].value;
            });
  
            childrenRows.push({
              key: `${i}-${childRowIdx}`,
              ...rowValue
            })
          });
        }

        // add parent values
        arr.push({
          key: i,
          Account: trialBalance[i].title,
          fwbold: true,
          children: childrenRows,
          ...total
        })
      }
    }

    return arr;
  }, [trialBalance, columnTrialBalance]);

  const onExport = () => {
    const arr = [];
    sourceTrialBalance.map(x => {
      if (x.Account === "Total") {
        arr.push({});
        arr.push({
          Account: x.Account,
          Debit: thousandSepartors(x.Debit, 0),
          Credit: thousandSepartors(x.Credit, 0),
          [`YTD Debit`]: thousandSepartors(x["YTDDebit"], 0),
          [`YTD Credit`]: thousandSepartors(x["YTDCredit"], 0),
        });
      } else {
        arr.push({});
        arr.push({
          Account: x.Account
        });
  
        x.children.map((child) => {
          arr.push({
            Account: child.Account,
            Debit: thousandSepartors(child.Debit, 0),
            Credit: thousandSepartors(child.Credit, 0),
            [`YTD Debit`]: thousandSepartors(child["YTDDebit"], 0),
            [`YTD Credit`]: thousandSepartors(child["YTDCredit"], 0),
          });
  
          return false;
        });
      }

      return false;
    });

    const colWidth = [ {wch: 25}, {wch: 12}, {wch: 12}, {wch: 12}, {wch: 12} ];
    exportXlsx(arr, colWidth, "Sheet1", `Trial Balance - ${formatDMY(filterDate)}`);
  } 

  return (
    <div className="body-wrapper">
      <PageTitleWrapper className="title-wrapper">
        <div>
          <h2>{reportInfo?.reportName ?? "Trial Balance"}</h2>
          <p className="mb-0">{reportInfo?.reportOrg ?? "-"}</p>
        </div>
        <div>
          <DatePicker 
            value={filterDate}
            onChange={(_, dateString) => setFilterDate(moment(dateString))}
          />
          <Button name="Update" className="ml-2" onClick={() => setIsUpdate(!isUpdate)} />
          <Button 
            name="Export" 
            className="ml-2" 
            icon={<DownloadOutlined />}
            onClick={() => onExport()} 
          />
        </div>
      </PageTitleWrapper>

      {
        !finishLoad && (
          <Loading />
        )
      }

      {
        finishLoad && (
          <DataTable 
            columns={columnTrialBalance} 
            sources={sourceTrialBalance} 
            pagination={false}
            defaultExpandAllRows={true}
            scroll={{ y: 680 }}
          />
        )
      }
    </div>
  );
}


export default TrialBalance;
